import { payloadClient } from '@/core/api';
import type { Where } from '@/core/api';
import type { Order } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Query keys for better type safety and reusability
export const orderKeys = {
  all: ['orders'] as const,
  lists: () => [...orderKeys.all, 'list'] as const,
  list: (params: OrderQueryParams) => [...orderKeys.lists(), params] as const,
  details: () => [...orderKeys.all, 'detail'] as const,
  detail: (id: string) => [...orderKeys.details(), id] as const,
} as const;

// Types for better type safety
export interface OrderQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Order | `-${keyof Order}`;
  disabled?: boolean;
}

export interface OrderUpdateParams {
  id: string;
  data: Partial<Order>;
}

// Hooks with improved TypeScript types
export const useOrders = (params?: OrderQueryParams) => {
  return useQuery({
    queryKey: orderKeys.list(params ?? {}),
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'orders',
        ...params,
      });
      return response;
    },
  });
};

export const useOrder = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: orderKeys.detail(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'orders',
        id,
      });
      return response;
    },
  });
};

export const useCreateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Order>) => {
      const response = await payloadClient.create({
        collection: 'orders',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: OrderUpdateParams) => {
      const response = await payloadClient.updateById({
        collection: 'orders',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};

export const useDeleteOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'orders',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};
