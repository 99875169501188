import { payloadClient } from '@/core/api';
import type { Config } from '@johanniter-offshore/backend';
import { type ReactNode, createContext, useContext, useEffect, useState } from 'react';

type User = Config['collections']['users'];

type AuthContextType = {
  isAuthenticated: boolean;
  user: User | null;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (data: User) => void;
  initialized: boolean;
  loading: boolean;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  login: async () => {},
  logout: async () => {},
  updateUser: async () => {},
  initialized: false,
  loading: true,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = !!user;

  useEffect(() => {
    async function initializeAuth() {
      try {
        const token = await payloadClient.getToken();
        if (token) {
          const meData = await payloadClient.me();
          if (meData) {
            setUser(meData.user);
          }
        }
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    }

    initializeAuth();
  }, []);

  const login = async ({ email, password }: { email: string; password: string }) => {
    setLoading(true);
    try {
      const data = await payloadClient.login({ email, password });
      setUser(data.user);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    await payloadClient.logout();
    setUser(null);
  };

  const updateUser = (data: User) => {
    setUser(data);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, updateUser, initialized, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
