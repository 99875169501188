import { useCurrentUserOrganization, useUpdateOrganization } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const organizationFormSchema = z.object({
  name: z.string().min(1),
});

type OrganizationFormValues = z.infer<typeof organizationFormSchema>;

export function ManageOrganization() {
  const { t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { data: organization } = useCurrentUserOrganization();
  const updateOrganization = useUpdateOrganization();
  const { user: currentUser } = useAuth();

  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (organization) {
      form.reset({
        name: organization.name,
      });
    }
  }, [organization, form]);

  const handleSubmit = async (data: OrganizationFormValues) => {
    if (!organization || currentUser?.role === 'customer-employee') return;
    setIsLoading(true);
    try {
      await updateOrganization.mutateAsync({ id: organization.id, data });
      toast.success(t('organization.organizationUpdatedSuccessfully'));
    } catch (error) {
      form.setError('root', { type: 'manual', message: t('errors.unknownError') });
    } finally {
      setIsLoading(false);
    }
  };

  const isCustomerEmployee = currentUser?.role === 'customer-employee';

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <PageHeading>{t('organization.organization')}</PageHeading>
        <Button onClick={form.handleSubmit(handleSubmit)} disabled={isLoading || isCustomerEmployee}>
          {isLoading ? t('common.saving') : t('common.save')}
        </Button>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{t('organization.organization')}</CardTitle>
          <CardDescription>{t('organization.editOrganizationDescription')}</CardDescription>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <CardContent>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>{t('common.name')}</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={isCustomerEmployee} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </form>
        </Form>
      </Card>
    </div>
  );
}
