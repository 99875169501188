export const fieldType = ['string', 'number', 'boolean', 'date'] as const;
export type FieldType = (typeof fieldType)[number];

export type JsonSchemaType = {
  type: 'object';
  properties: Record<
    string,
    {
      type: FieldType;
      format?: 'date-time';
      description: string;
      [k: string]: unknown;
    }
  >;
  required: string[];
  propertyOrder: string[];
  [k: string]: unknown;
};

export interface ResolvedChildrenMap {
  [key: string]: {
    metadataSchema: JsonSchemaType;
    quantity: number;
    children?: ResolvedChildrenMap;
  };
}
