import { useUpdateUser } from '@/core/api';
import { useAuth } from '@/core/auth';
import { useTheme } from '@/core/theme';
import { PageHeading } from '@/shared/components/layout';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { Globe, SunMoon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import type { z } from 'zod';

import { userFormSchema } from '../schema';

type UserFormValues = z.infer<typeof userFormSchema>;

export function AccountForm() {
  const { t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { user: currentUser, updateUser } = useAuth();
  const updateUserMutation = useUpdateUser();
  const { mode: currentThemeMode, setThemeMode } = useTheme();

  const form = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      language: currentUser?.language || 'de-DE',
      themeMode: currentThemeMode || 'system',
    },
  });

  useEffect(() => {
    if (currentUser) {
      form.reset({
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        language: currentUser.language || 'de-DE',
        themeMode: currentThemeMode,
      });
    }
  }, [currentUser, form, currentThemeMode]);

  const handleSubmit = async (data: UserFormValues) => {
    if (!currentUser) return;
    setIsLoading(true);
    try {
      const newUser = await updateUserMutation.mutateAsync({
        id: currentUser.id,
        data: { firstName: data.firstName, lastName: data.lastName, language: data.language },
      });
      updateUser(newUser);
      setThemeMode(data.themeMode);
      toast.success(t('users.userUpdatedSuccessfully'));
    } catch (error) {
      form.setError('root', { type: 'manual', message: t('errors.unknownError') });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl space-y-6">
      <PageHeading>{t('auth.myAccount')}</PageHeading>

      <Card>
        <CardHeader>
          <CardTitle>{t('settings.generalSettings')}</CardTitle>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <CardContent>
              <div className="space-y-6">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t('users.firstName')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t('users.lastName')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="language"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('settings.language')}</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <div className="flex items-center gap-3">
                              <Globe className="size-4 " />
                              <SelectValue placeholder={t('settings.selectLanguage')} />
                            </div>
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="de-DE">{t('settings.german')}</SelectItem>
                          <SelectItem value="en-US">{t('settings.english')}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="themeMode"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('settings.themeMode')}</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <div className="flex items-center gap-3">
                              <SunMoon className="size-4" />
                              <SelectValue placeholder={t('settings.selectThemeMode')} />
                            </div>
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="light">{t('settings.light')}</SelectItem>
                          <SelectItem value="dark">{t('settings.dark')}</SelectItem>
                          <SelectItem value="blue">{t('settings.blue')}</SelectItem>
                          <SelectItem value="system">{t('settings.system')}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? t('common.saving') : t('common.save')}
                </Button>
              </div>
            </CardContent>
          </form>
        </Form>
      </Card>
    </div>
  );
}
