import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/shared/components/ui';
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/shared/components/ui';
import type { RouteProp } from '@/shared/types/route-prop';
import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface SidebarLinkProps {
  route: RouteProp;
  isActive?: boolean;
  currentPath: string;
}

export const SidebarLink = ({ route, isActive, currentPath }: SidebarLinkProps) => {
  const { t } = useIntl();
  const hasSubItems = route.items && route.items.length > 0;

  if (hasSubItems && route.items) {
    return (
      <Collapsible asChild defaultOpen className="group/collapsible">
        <SidebarMenuItem>
          <CollapsibleTrigger asChild>
            <SidebarMenuButton tooltip={t(route.label)}>
              {route.icon && <route.icon className="size-4" />}
              <span>{t(route.label)}</span>
              <ChevronRight className="ml-auto size-4 transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
            </SidebarMenuButton>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <SidebarMenuSub>
              {route.items.map((subItem) => (
                <SidebarMenuSubItem key={subItem.to}>
                  <SidebarMenuSubButton asChild isActive={currentPath.startsWith(subItem.to)}>
                    <Link to={subItem.to} className={cn(currentPath.startsWith(subItem.to) && 'font-medium')}>
                      <span>{t(subItem.label)}</span>
                    </Link>
                  </SidebarMenuSubButton>
                </SidebarMenuSubItem>
              ))}
            </SidebarMenuSub>
          </CollapsibleContent>
        </SidebarMenuItem>
      </Collapsible>
    );
  }

  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild tooltip={t(route.label)} data-active={isActive}>
        <Link to={route.to}>
          {route.icon && <route.icon className="size-4" />}
          <span>{t(route.label)}</span>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};
