import { payloadClient } from '@/core/api';
import type { Where } from '@/core/api';
import type { Category } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Query keys for better type safety and reusability
export const categoryKeys = {
  all: ['categories'] as const,
  lists: () => [...categoryKeys.all, 'list'] as const,
  list: (params: CategoryQueryParams) => [...categoryKeys.lists(), params] as const,
  details: () => [...categoryKeys.all, 'detail'] as const,
  detail: (id: string) => [...categoryKeys.details(), id] as const,
} as const;

// Types for better type safety
export interface CategoryQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Category | `-${keyof Category}`;
  disabled?: boolean;
}

export interface CategoryUpdateParams {
  id: string;
  data: Partial<Category>;
}

// Hooks with improved TypeScript types
export const useCategories = (params?: CategoryQueryParams) => {
  return useQuery({
    queryKey: categoryKeys.list(params ?? {}),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'categories',
        ...params,
      });
      return response;
    },
  });
};

export const useCategory = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: categoryKeys.detail(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'categories',
        id,
      });
      return response;
    },
  });
};

export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Category>) => {
      const response = await payloadClient.create({
        collection: 'categories',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: categoryKeys.all });
    },
  });
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: CategoryUpdateParams) => {
      const response = await payloadClient.updateById({
        collection: 'categories',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: categoryKeys.all });
    },
  });
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'categories',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: categoryKeys.all });
    },
  });
};
