import { Link } from 'react-router-dom';

export const Logo = () => (
  <Link to="/" className="flex items-center gap-2 p-2 group/logo">
    <div className="flex size-8 shrink-0 items-center justify-center text-primary-foreground">
      <img src="/johanniter-logo-small.svg" alt="Johanniter Logo" />
    </div>
    <div className="grid text-left text-sm leading-tight group-data-[collapsible=icon]/sidebar:hidden">
      <span className="font-semibold">Johanniter</span>
      <span className="text-xs text-muted-foreground">Offshore Portal</span>
    </div>
  </Link>
);
