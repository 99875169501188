import type { User } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useUsers = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof User | `-${keyof User}`;
}) => {
  return useQuery({
    queryKey: ['users', params],
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'users',
        ...params,
      });
      return response;
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<User> }) => {
      const response = await payloadClient.updateById({
        collection: 'users',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};
