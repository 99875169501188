import { payloadClient } from '@/core/api';
import type { Where } from '@/core/api';
import type { Article } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Query keys for better type safety and reusability
export const articleKeys = {
  all: ['articles'] as const,
  lists: () => [...articleKeys.all, 'list'] as const,
  list: (params: ArticleQueryParams) => [...articleKeys.lists(), params] as const,
  details: () => [...articleKeys.all, 'detail'] as const,
  detail: (id: string) => [...articleKeys.details(), id] as const,
  resolvedChildren: (id: string) => [...articleKeys.details(), id, 'resolved-children'] as const,
} as const;

// Types for better type safety
export interface ArticleQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Article | `-${keyof Article}`;
  disabled?: boolean;
}

export interface ArticleUpdateParams {
  id: string;
  data: Partial<Article>;
}

export interface ArticleChildData {
  id?: string;
  metadata?: Record<string, unknown>;
  expiryDate?: string | null;
  children?: ArticleChildrenData;
}

export interface ArticleChildrenData {
  [key: string]: ArticleChildData[];
}

export interface CreateArticleWithChildrenData extends Omit<Article, 'id' | 'createdAt' | 'updatedAt' | 'children'> {
  children?: ArticleChildrenData;
}

// Hooks with improved TypeScript types
export const useArticles = (params?: ArticleQueryParams) => {
  return useQuery({
    queryKey: articleKeys.list(params ?? {}),
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'articles',
        ...params,
      });
      return response;
    },
  });
};

export const useArticle = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: articleKeys.detail(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'articles',
        id,
      });
      return response;
    },
  });
};

export const useCreateArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Article>) => {
      const response = await payloadClient.create({
        collection: 'articles',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: articleKeys.all });
    },
  });
};

export const useCreateArticleWithChildren = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateArticleWithChildrenData) => {
      const response = await payloadClient.customRequest<{ article: Article }>({
        subpath: '/articles/create-with-children',
        method: 'POST',
        data: data as unknown as Record<string, unknown>,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: articleKeys.all });
    },
  });
};

export const useUpdateArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: ArticleUpdateParams) => {
      const response = await payloadClient.updateById({
        collection: 'articles',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: articleKeys.all });
    },
  });
};

export const useDeleteArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'articles',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: articleKeys.all });
    },
  });
};

export const useResolvedArticleChildren = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: articleKeys.resolvedChildren(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.customRequest<{ children: ArticleChildrenData }>({
        subpath: `/articles/${id}/resolved-children`,
        method: 'GET',
      });
      return response;
    },
  });
};

export const useUpdateArticleChildren = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: ArticleChildrenData }) => {
      const response = await payloadClient.customRequest<{ article: Article }>({
        subpath: `/articles/${id}/update-children`,
        method: 'POST',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: articleKeys.all });
    },
  });
};
