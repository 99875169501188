import { useCategories, useCreateCategory, useUpdateCategory } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
} from '@/shared/components/metadata/metadata-builder';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/shared/components/ui';
import { cn } from '@/shared/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { Check, ChevronsUpDown, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { categoryFormSchema } from '../schema';
import type { CategoryFormValues } from '../types';

export function ManageCategory() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const createCategory = useCreateCategory();
  const updateCategory = useUpdateCategory();
  const navigate = useNavigate();
  const { data: categoriesData } = useCategories({ limit: 0 });
  const [open, setOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const isEditMode = !!id;

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const form = useForm<CategoryFormValues>({
    resolver: zodResolver(categoryFormSchema),
    defaultValues: {
      name: '',
      description: '',
      metadata: [],
      parentId: null,
    },
  });

  useEffect(() => {
    if (isEditMode && categoriesData) {
      const category = categoriesData.docs.find((cat: { id: string }) => cat.id === id);
      if (category) {
        form.reset({
          name: category.name,
          description: category.description || '',
          metadata: jsonSchemaToFormFields(category.metadata as JsonSchemaType),
          parentId: (category.parent as string) || null,
        });
      }
    }
  }, [isEditMode, id, categoriesData, form]);

  const handleSubmit = async (data: CategoryFormValues) => {
    if (!isJuhAdmin) return;
    const jsonSchema = formFieldsToJsonSchema(data.metadata);

    try {
      const payload = {
        name: data.name,
        description: data.description,
        metadata: jsonSchema,
        parent: data.parentId || null,
      };

      if (isEditMode) {
        await updateCategory.mutateAsync({ id, data: payload });
        toast.success(t('common.savedSuccessfully'));
      } else {
        await createCategory.mutateAsync(payload);
        toast.success(t('common.savedSuccessfully'));
      }
      navigate('/juh/article-management/categories');
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
        const payloadError = error as { response?: { errors?: Array<{ message: string }> } };
        const errorMessage = payloadError.response?.errors?.[0]?.message;

        switch (errorMessage) {
          case 'MaxCategoryDepthExceeded':
            form.setError('parentId', {
              type: 'manual',
              message: t('categories.maxCategoryDepthExceeded'),
            });
            break;
          case 'SelfParentCategoryError':
            form.setError('parentId', {
              type: 'manual',
              message: t('categories.selfParentCategoryError'),
            });
            break;
          case 'CircularReferenceError':
            form.setError('parentId', {
              type: 'manual',
              message: t('categories.circularCategoryReferenceError'),
            });
            break;
          case 'ParentChangeRequiredFieldsError':
            form.setError('parentId', {
              type: 'manual',
              message: t('categories.parentChangeRequiredFieldsError'),
            });
            break;
          case 'MetadataUpdateValidationFailed':
            handleMetadataValidationErrors(error, data.metadata, jsonSchema, form, t);
            break;
          default:
            form.setError('root', { type: 'manual', message: t('errors.unknownError') });
        }
      } else {
        form.setError('root', { type: 'manual', message: t('errors.unknownError') });
      }
    }
  };

  return (
    <>
      <PageHeader
        title={isEditMode ? t('common.edit') : t('common.create')}
        backHref="/juh/article-management/categories"
        actions={
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={() => navigate('/juh/article-management/categories')}>
              {t('common.discard')}
            </Button>
            <Button
              onClick={form.handleSubmit(handleSubmit)}
              disabled={(isEditMode ? updateCategory.isPending : createCategory.isPending) || !isJuhAdmin}
            >
              {isEditMode
                ? updateCategory.isPending
                  ? t('common.updating')
                  : t('common.update')
                : createCategory.isPending
                  ? t('common.creating')
                  : t('common.create')}
            </Button>
          </div>
        }
      />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="grid gap-6 md:grid-cols-2">
          <Card className="md:col-span-2">
            <CardHeader>
              <CardTitle>{t('categories.categoryDetails')}</CardTitle>
              <CardDescription>{t('categories.categoryDetailsDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t('categories.categoryName')}</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!isJuhAdmin} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('categories.categoryDescription')}</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!isJuhAdmin} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
          </Card>
          <Card className="md:col-span-2">
            <CardHeader>
              <CardTitle>{t('metadata.metadata')}</CardTitle>
              <CardDescription>{t('categories.categoryMetadataDescription')}</CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <MetadataBuilder disabled={!isJuhAdmin} />
            </CardContent>
          </Card>
          <Card className="md:col-span-2">
            <CardHeader>
              <CardTitle>{t('categories.parentCategory')}</CardTitle>
              <CardDescription>{t('categories.parentCategoryDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <FormField
                control={form.control}
                name="parentId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('categories.parentCategory')}</FormLabel>
                    <Popover open={open} onOpenChange={setOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn('w-full justify-between', !field.value && 'text-muted-foreground')}
                            disabled={!isJuhAdmin}
                            onClick={() => setOpen(!open)}
                          >
                            {field.value
                              ? categoriesData?.docs.find((category: { id: string }) => category.id === field.value)
                                  ?.name
                              : t('categories.selectParentCategory')}

                            {field.value ? (
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  form.setValue('parentId', null);
                                }}
                                className="size-4 opacity-50 hover:opacity-100"
                              />
                            ) : (
                              <ChevronsUpDown className="size-4 opacity-50" />
                            )}
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-[300px] p-0">
                        <Command value={field.value || undefined}>
                          <CommandInput placeholder={t('common.search')} />
                          <CommandList>
                            <CommandEmpty>{t('categories.noCategoryFound')}</CommandEmpty>
                            <CommandGroup>
                              {categoriesData?.docs.map((category: { id: string; name: string }) => (
                                <CommandItem
                                  value={category.name}
                                  key={category.id}
                                  onSelect={() => {
                                    form.setValue('parentId', category.id);
                                    setOpen(false);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      'mr-2 h-4 w-4',
                                      category.id === field.value ? 'opacity-100' : 'opacity-0',
                                    )}
                                  />
                                  {category.name}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
        </form>
      </Form>
    </>
  );
}
