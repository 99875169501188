import { payloadClient, useArticle, useResolvedTemplateMetadata, useTemplate } from '@/core/api';
import { PageHeading } from '@/shared/components/layout';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  /* Separator */
  Skeleton,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import type { Article, Media, Template } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import {
  ChevronLeft,
  Download,
  /* ExternalLink */
  Loader2,
} from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

/* function ArticleContentsCard({ article }: { article?: Article }) {
  const { t } = useIntl();

  if (!article?.children?.length) return null;

  const groupedChildren = article.children.reduce(
    (acc, child) => {
      if (typeof child === 'object' && child.template) {
        if (!acc[child.template as string]) {
          acc[child.template as string] = [];
        }
        acc[child.template as string].push(child as unknown as Article);
      }
      return acc;
    },
    {} as Record<string, Article[]>,
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('contents')}</CardTitle>
        <CardDescription>{t('contentsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {Object.keys(groupedChildren).length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('expiryDate')}</TableHead>
                <TableHead>{t('actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.entries(groupedChildren).map(([childTemplateId, children]) => (
                <ChildTemplateGroup key={childTemplateId} childTemplateId={childTemplateId} childArticles={children} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div>{t('noContents')}</div>
        )}
      </CardContent>
    </Card>
  );
} 

function ChildTemplateGroup({ childTemplateId, childArticles }: { childTemplateId: string; childArticles: Article[] }) {
  const { data: childTemplate, isLoading } = useTemplate(childTemplateId);

  if (isLoading) {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Skeleton className="h-10 w-full" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell colSpan={6} className="bg-muted/50 font-semibold">
          {childTemplate?.name} ({childArticles.length})
        </TableCell>
      </TableRow>
      {childArticles.map((childArticle) => (
        <ChildTemplateRow key={childArticle.id} childArticle={childArticle} />
      ))}
    </>
  );
}

function ChildTemplateRow({ childArticle }: { childArticle: Article }) {
  const handleOpenArticle = () => {
    window.open(`/c/my-backpacks/${childArticle.id}`, '_blank');
  };

  return (
    <TableRow>
      <TableCell>
        {childArticle.expiryDate ? DateTime.fromISO(childArticle.expiryDate).toFormat('dd.MM.yyyy') : '-'}
      </TableCell>
      <TableCell>
        <Button variant="ghost" size="icon" onClick={handleOpenArticle}>
          <ExternalLink className="size-4" />
        </Button>
      </TableCell>
    </TableRow>
  );
} */

function ArticleMetadataCard({ article }: { article?: Article }) {
  const { t } = useIntl();
  const { data: metadataDefinition, isLoading } = useResolvedTemplateMetadata(article?.template as string);

  const renderMetadataValue = (key: string, value: unknown, type: string) => {
    if (type === 'date' && typeof value === 'string') {
      return DateTime.fromISO(value).toFormat('dd.MM.yyyy');
    }
    return String(value);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('metadata.metadata')}</CardTitle>
        <CardDescription>{t('metadata.metadataDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {!article || isLoading ? (
          <Skeleton className="h-[300px] w-full" />
        ) : metadataDefinition && article?.metadata && typeof article.metadata === 'object' ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px]">{t('common.key')}</TableHead>
                <TableHead>{t('common.value')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {metadataDefinition.propertyOrder.map((key) => {
                const fieldDefinition = metadataDefinition.properties[key];
                const value = (article.metadata as Record<string, unknown>)[key];
                if (value !== undefined) {
                  return (
                    <TableRow key={key}>
                      <TableCell>{fieldDefinition.description}</TableCell>
                      <TableCell>{renderMetadataValue(key, value, fieldDefinition.type)}</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
            {metadataDefinition.propertyOrder.length === 0 && (
              <TableCaption className="text-muted-foreground py-4 text-center text-sm">
                {t('metadata.noMetadata')}
              </TableCaption>
            )}
          </Table>
        ) : (
          <Table>
            <TableCaption className="text-muted-foreground py-4 text-center text-sm">
              {t('metadata.noMetadata')}
            </TableCaption>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

export function ArticleDetailsCard({ templateData, isLoading }: { templateData?: Template; isLoading: boolean }) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.details.detailsDescription')}</CardTitle>
        <CardDescription>{t('articles.details.detailsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">{t('articles.article')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? <Skeleton className="h-4 w-full" /> : templateData?.name || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-semibold">{t('common.description')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? <Skeleton className="h-4 w-full" /> : templateData?.description || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function ArticleDatesCard({ articleData, isLoading }: { articleData?: Article; isLoading: boolean }) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.details.dates')}</CardTitle>
        <CardDescription>{t('articles.details.datesDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-semibold">{t('articles.expiryDate')}</TableCell>
              <TableCell className="w-full">
                {isLoading ? (
                  <Skeleton className="h-4 w-full" />
                ) : articleData?.expiryDate ? (
                  DateTime.fromISO(articleData.expiryDate).toFormat('dd.MM.yyyy')
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function ArticleFilesCard({
  articleData,
  templateData,
  isLoading,
}: {
  articleData?: Article;
  templateData?: Template;
  isLoading: boolean;
}) {
  const { t } = useIntl();
  const [downloadingIndex, setDownloadingIndex] = useState<number | null>(null);

  const allFiles = useMemo(() => {
    const articleFiles = articleData?.articleFiles || [];
    const templateFiles = templateData?.files || [];
    return [...articleFiles, ...templateFiles];
  }, [articleData, templateData]);

  const handleDownload = async (file: { file: string | Media; name: string }, index: number) => {
    setDownloadingIndex(index);

    try {
      let url: string;
      let filename: string;

      if (typeof file.file === 'string') {
        const mediaData = await payloadClient.findById({
          collection: 'media',
          id: file.file as string,
        });
        if (!mediaData || !mediaData.url) {
          toast.error(t('fileNotFound'));
          return;
        }
        url = mediaData.url;
        filename = mediaData.filename || file.name;
      } else {
        url = file.file.url || '';
        filename = file.file.filename || file.name;
      }

      const request = await payloadClient.createRequest(url.replace('/api', ''), { method: 'GET' });
      const response = await payloadClient.fetcher(request);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      toast.error(t('errorDownloadingFile'));
    } finally {
      setDownloadingIndex(null);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.articleFiles')}</CardTitle>
        <CardDescription>{t('articles.details.filesDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Skeleton className="h-[300px] w-full" />
        ) : allFiles.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px]">{t('common.name')}</TableHead>
                <TableHead>{t('common.description')}</TableHead>
                <TableHead className="w-[100px]">{t('common.actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {allFiles.map((file, index) => (
                <TableRow key={index}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{file.description || '-'}</TableCell>
                  <TableCell>
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDownload(file, index)}
                      disabled={downloadingIndex === index}
                    >
                      {downloadingIndex === index ? (
                        <Loader2 className="size-4 animate-spin" />
                      ) : (
                        <Download className="size-4" />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="text-muted-foreground py-4 text-center text-sm">
            <p>{t('articles.details.noFilesAssigned')}</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export function ArticleDetail() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: articleData, isLoading: isArticleLoading } = useArticle(id as string);
  const { data: templateData, isLoading: isTemplateLoading } = useTemplate(articleData?.template as string, {
    disabled: isArticleLoading,
  });

  const isLoading = isArticleLoading || isTemplateLoading;

  return (
    <div className="mx-auto grid w-full flex-1 auto-rows-max gap-4">
      <div className="flex items-center gap-4">
        <Button variant="outline" size="icon" className="size-7" onClick={() => navigate('/c/my-articles')}>
          <ChevronLeft className="size-4" />
          <span className="sr-only">{t('common.back')}</span>
        </Button>
        <PageHeading>{t('articles.articleDetails')}</PageHeading>
      </div>

      <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
        <ArticleDetailsCard templateData={templateData} isLoading={isLoading} />
        <ArticleDatesCard articleData={articleData} isLoading={isLoading} />

        <div className="col-span-full">
          <ArticleMetadataCard article={articleData} />
        </div>

        <div className="col-span-full">
          <ArticleFilesCard articleData={articleData} templateData={templateData} isLoading={isLoading} />
        </div>
        {/* {!!articleData?.children?.length && (
            <div className="col-span-full">
              <Separator />
            </div>
          )}

          <div className="col-span-full">
            <ArticleContentsCard article={articleData} />
          </div> */}
      </div>
    </div>
  );
}
