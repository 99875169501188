import type { User } from '@johanniter-offshore/backend';
import { redirect } from 'react-router-dom';

export const redirectBasedOnRole = (user: User) => {
  if (user.role === 'customer-admin' || user.role === 'customer-employee') {
    return redirect('/c');
  } else if (user.role === 'juh-admin' || user.role === 'juh-employee') {
    return redirect('/juh');
  }
  return null;
};

export const publicRoute = (user: User | null) => {
  return user ? redirectBasedOnRole(user) : null;
};

export const privateRoute = (allowedRoles: string[]) => (user: User | null) => {
  if (!user) {
    return redirect('/auth/login');
  }
  if (!allowedRoles.includes(user.role)) {
    return redirect('/');
  }
  return null;
};

export const unknownRoute = (user: User | null) => {
  return user ? redirectBasedOnRole(user) : redirect('/auth/login');
};
