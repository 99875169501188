import { Separator, SidebarTrigger } from '@/shared/components/ui';
import type { RouteProp } from '@/shared/types/route-prop';
import type React from 'react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { BreadcrumbNav } from './breadcrumb-nav';

interface HeaderPortalProps {
  children: ReactNode;
}

export const HeaderPortal: React.FC<HeaderPortalProps> = ({ children }: { children: ReactNode }) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.getElementById('header-portal-container');
    if (container) {
      setPortalContainer(container);
    }
  }, []);

  if (!portalContainer) return null;

  return createPortal(children, portalContainer);
};

interface HeaderProps {
  routes: RouteProp[];
}

export const Header: React.FC<HeaderProps> = () => (
  <header className="flex h-16 shrink-0 items-center gap-2 px-4">
    <div className="flex items-center gap-3">
      <SidebarTrigger className="text-muted-foreground size-4" />
      <Separator orientation="vertical" className="h-4 hidden md:block" />
      <BreadcrumbNav />
    </div>
    <div id="header-portal-container" className="relative ml-auto flex-1 md:grow-0" />
  </header>
);
