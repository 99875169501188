import { payloadClient } from '@/core/api';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import { Download, Loader2, Plus, Trash } from 'lucide-react';
import type React from 'react';
import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'sonner';

interface FileUploadCardProps {
  disabled?: boolean;
  title: string;
  description: string;
  fieldArrayName: string;
}

export function FileUploadCard({ disabled = false, title, description, fieldArrayName }: FileUploadCardProps) {
  const { t } = useIntl();
  const { control, setValue } = useFormContext();
  const [downloadingIndex, setDownloadingIndex] = useState<number | null>(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue(`${fieldArrayName}.${index}.file`, file);
      setValue(`${fieldArrayName}.${index}.name`, file.name);
      setValue(`${fieldArrayName}.${index}.description`, '');
    }
  };

  const handleAddFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        append({ name: file.name, file: file, description: '' });
      }
    };
    input.click();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDownload = async (file: any, index: number) => {
    setDownloadingIndex(index);

    try {
      let url: string;
      let filename: string;
      if (file.file instanceof File) {
        url = URL.createObjectURL(file.file);
        filename = file.file.name;
      } else {
        const mediaData = await payloadClient.findById({
          collection: 'media',
          id: file.file as string,
        });
        if (!mediaData) {
          toast.error(t('errors.fileNotFound'));
          return;
        }
        filename = mediaData.filename || 'download';

        if (!mediaData.url) {
          toast.error(t('errors.fileNotFound'));
          return;
        }

        const request = await payloadClient.createRequest(mediaData.url?.replace('/api', ''), { method: 'GET' });
        const response = await payloadClient.fetcher(request);
        const blob = await response.blob();
        url = URL.createObjectURL(blob);
      }

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(t('errors.errorDownloadingFile'));
    } finally {
      setDownloadingIndex(null);
    }
  };

  const files = useWatch({ control, name: fieldArrayName }) || [];

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Table className="border-b">
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/3 pl-5">{t('common.file')}</TableHead>
              <TableHead>{t('common.description')}</TableHead>
              <TableHead className="w-[120px] pr-5">{t('common.actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell className="align-top pl-5">
                  <FormField
                    control={control}
                    name={`${fieldArrayName}.${index}.name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="flex items-center">
                            <Input {...field} disabled={disabled} className="h-8" />
                            <input
                              id={`file-upload-${index}`}
                              type="file"
                              className="hidden"
                              onChange={(e) => handleFileChange(e, index)}
                              disabled={disabled}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="align-top">
                  <FormField
                    control={control}
                    name={`${fieldArrayName}.${index}.description`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} disabled={disabled} className="h-8" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="align-top pr-5">
                  <div className="flex">
                    <Button
                      type="button"
                      variant="ghost"
                      className="size-8"
                      size="icon"
                      onClick={() => handleDownload(files[index], index)}
                      disabled={disabled || downloadingIndex === index}
                    >
                      {downloadingIndex === index ? (
                        <Loader2 className="size-4 animate-spin" />
                      ) : (
                        <Download className="size-4" />
                      )}
                    </Button>
                    <Button
                      type="button"
                      variant="ghost"
                      className="size-8"
                      size="icon"
                      onClick={() => remove(index)}
                      disabled={disabled}
                    >
                      <Trash className="size-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          type="button"
          variant="ghost"
          onClick={handleAddFile}
          disabled={disabled}
          className={cn('w-full rounded-none rounded-b text-muted-foreground hover:text-foreground')}
        >
          <Plus className="size-4" />
        </Button>
      </CardContent>
    </Card>
  );
}
