import type { RouteProp } from '@/shared/types/route-prop';
import { Building2, ClipboardList, Package2, Settings } from 'lucide-react';

export const customerRoutes: RouteProp[] = [
  { to: '/c/my-orders', icon: ClipboardList, label: 'orders.myOrders' },
  { to: '/c/my-articles', icon: Package2, label: 'articles.myArticles' },
  {
    to: '/c/my-organization',
    icon: Building2,
    label: 'organizations.myOrganizations',
    items: [
      { to: '/c/my-organization/organization', label: 'organizations.organization' },
      { to: '/c/my-organization/users', label: 'users.users' },
    ],
  },
] as const;

export const juhRoutes: RouteProp[] = [
  {
    to: '/juh/order-management',
    icon: ClipboardList,
    label: 'orders.management',
    items: [
      { to: '/juh/order-management/orders', label: 'orders.allOrders' },
      { to: '/juh/order-management/objects', label: 'orders.objects' },
      { to: '/juh/order-management/metadata', label: 'orders.metadata' },
    ],
  },
  {
    to: '/juh/article-management',
    icon: Package2,
    label: 'articles.management',
    items: [
      { to: '/juh/article-management/articles', label: 'stock.stock' },
      { to: '/juh/article-management/templates', label: 'templates.templates' },
      { to: '/juh/article-management/categories', label: 'categories.categories' },
      { to: '/juh/article-management/metadata', label: 'articles.metadata' },
    ],
  },
  {
    to: '/juh/customer-management',
    icon: Building2,
    label: 'organizations.management',
    items: [{ to: '/juh/customer-management/organizations', label: 'organizations.organizations' }],
  },
  {
    to: '/juh/settings',
    icon: Settings,
    label: 'settings.juhSettings',
    items: [{ to: '/juh/settings/users', label: 'users.users' }],
  },
] as const;
