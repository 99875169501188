import { PageHeading } from '@/shared/components/layout';
import { ArticleTable } from '@/shared/components/tables';
import { Button } from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function CustomerArticleOverview() {
  const { t } = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <PageHeading>{t('articles.myArticles')}</PageHeading>
      </div>

      <ArticleTable
        customActions={<></>}
        customCellActions={(article) => (
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate(`/c/my-articles/${article.id}`)}
            aria-label={t('articles.viewDetails')}
          >
            <ArrowRight className="size-4" />
          </Button>
        )}
      />
    </>
  );
}
