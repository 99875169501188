import type { PaginatedDocs } from '@/core/api';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/shared/components/ui';
import { cn } from '@/shared/utils';
import type { Category } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { Check, ChevronsUpDown, X } from 'lucide-react';
import { useState } from 'react';
import type { Control, UseFormSetValue } from 'react-hook-form';

import type { CategoryFormValues } from '../types';

interface TemplateCategoryCardProps {
  control: Control<CategoryFormValues>;
  setValue: UseFormSetValue<CategoryFormValues>;
  categoriesData: PaginatedDocs<Pick<Category, keyof Category>> | undefined;
}

export function TemplateCategoryCard({ control, setValue, categoriesData }: TemplateCategoryCardProps) {
  const { t } = useIntl();
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('categories.category')}</CardTitle>
        <CardDescription>{t('templates.selectTemplateCategory')}</CardDescription>
      </CardHeader>
      <CardContent>
        <FormField
          control={control}
          name="category"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('categories.category')}</FormLabel>
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn('w-full justify-between', !field.value && 'text-muted-foreground')}
                      onClick={() => setOpen(!open)}
                    >
                      {field.value
                        ? categoriesData?.docs.find((category) => category.id === field.value)?.name
                        : t('templates.selectTemplateCategory')}
                      {field.value ? (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setValue('category', null);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.stopPropagation();
                              setValue('category', null);
                            }
                          }}
                          role="button"
                          tabIndex={0}
                          className="opacity-50 hover:opacity-100"
                        >
                          <X className="ml-2 size-4 shrink-0 text-muted-foreground" />
                        </div>
                      ) : (
                        <ChevronsUpDown className="size-4 opacity-50" />
                      )}
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0">
                  <Command>
                    <CommandInput placeholder={t('categories.searchCategory')} />
                    <CommandList>
                      <CommandEmpty>{t('categories.noCategoryFound')}</CommandEmpty>
                      <CommandGroup>
                        {categoriesData?.docs.map((category) => (
                          <CommandItem
                            value={category.name}
                            key={category.id}
                            onSelect={() => {
                              setValue('category', category.id);
                              setOpen(false);
                            }}
                          >
                            <Check
                              className={cn('mr-2 h-4 w-4', category.id === field.value ? 'opacity-100' : 'opacity-0')}
                            />
                            {category.name}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
}
