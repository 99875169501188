import { Input } from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { Search } from 'lucide-react';
import { memo } from 'react';

export const SearchField = memo(
  ({ searchTerm, setSearchTerm }: { searchTerm: string; setSearchTerm: (term: string) => void }) => {
    const { t } = useIntl();
    return (
      <div className="relative w-full max-w-sm">
        <Search className="absolute left-2 top-1/2 -translate-y-1/2 text-muted-foreground size-4" />
        <Input
          type="search"
          placeholder={t('common.search')}
          className="pl-8 w-[300px]"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    );
  },
);
SearchField.displayName = 'SearchField';
