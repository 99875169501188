import {
  useCreateArticle,
  useCreateArticleWithChildren,
  useOrder,
  useResolvedTemplateMetadata,
  useTemplate,
  useUploadMedia,
} from '@/core/api';
import type { CreateArticleWithChildrenData } from '@/core/api/articles';
import { PageHeader } from '@/shared/components/layout/page-header';
import { Button, Form, Step, Stepper, useStepper } from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Article } from '@johanniter-offshore/backend';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import jsonSchemaToZod from 'json-schema-to-zod';
import { DateTime } from 'luxon';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import { ArticleAssignmentCard, ArticleDetailsCard, ArticleFilesCard, ArticleMetadataCard } from '../components';
import { ArticleChildren } from '../components/article-children';
import { useArticleErrorHandling } from '../hooks';
import { articleDetailsSchema, articleFilesSchema, assignmentSchema } from '../schema';
import type { ArticleDetailsFormValues, ArticleFilesFormValues, AssignmentFormValues } from '../types';

type StepperContextType = {
  portalContainer: HTMLDivElement | null;
  setPortalContainer: (container: HTMLDivElement | null) => void;
};

const StepperContext = createContext<StepperContextType>({
  portalContainer: null,
  setPortalContainer: () => {},
});

function ArticleFormLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-12 gap-6">{children}</div>
    </div>
  );
}

export function CreateArticle() {
  const { t } = useIntl();
  const createArticle = useCreateArticle();
  const createArticleWithChildren = useCreateArticleWithChildren();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleArticleError } = useArticleErrorHandling();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const { data: orderData } = useOrder(orderId || '', { disabled: !orderId });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [metadataSchema, setMetadataSchema] = useState<z.ZodObject<any, any>>(z.object({}));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [childrenSchema, setChildrenSchema] = useState<z.ZodType<any>>(z.object({}));

  const detailsForm = useForm<ArticleDetailsFormValues>({
    resolver: zodResolver(articleDetailsSchema),
    defaultValues: {
      template: '',
      expiryDate: null,
    },
  });

  const assignmentForm = useForm<AssignmentFormValues>({
    resolver: zodResolver(assignmentSchema),
    defaultValues: {
      order: null,
    },
  });

  const metadataForm = useForm({
    resolver: zodResolver(metadataSchema),
  });

  const childArticlesForm = useForm({
    resolver: zodResolver(childrenSchema),
  });

  const articleFilesForm = useForm<ArticleFilesFormValues>({
    resolver: zodResolver(articleFilesSchema),
    defaultValues: {
      articleFiles: [],
    },
  });

  const { data: templateData } = useTemplate(detailsForm.watch('template'), {
    disabled: !detailsForm.watch('template'),
  });
  const { data: templateMetadata } = useResolvedTemplateMetadata(templateData?.id);

  useEffect(() => {
    if (templateMetadata) {
      const zodSchemaString = jsonSchemaToZod(templateMetadata);
      const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');

      const zodSchema = eval(transformedSchema);
      setMetadataSchema(zodSchema);
      metadataForm.reset({});
    } else {
      setMetadataSchema(z.object({}));
      metadataForm.reset({});
    }
  }, [templateMetadata, metadataForm]);

  useEffect(() => {
    if (templateData?.isContainer) {
      detailsForm.setValue('expiryDate', null);
    }
  }, [templateData?.isContainer, detailsForm]);

  useEffect(() => {
    if (orderId) {
      assignmentForm.setValue('order', orderId);
    }
  }, [orderId, assignmentForm]);

  const uploadMedia = useUploadMedia();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChildrenSchemaChange = useCallback(
    (schema: z.ZodType) => {
      setChildrenSchema(schema);
      const currentValues = childArticlesForm.getValues();
      childArticlesForm.reset(currentValues);
    },
    [childArticlesForm],
  );

  const onSubmit = async () => {
    if (templateData?.isContainer) {
      setIsSubmitting(true);
    } else {
      const isValid = await validateForms();
      if (!isValid) return;
      setIsSubmitting(true);
    }

    try {
      const detailsData = detailsForm.getValues();
      const assignmentData = assignmentForm.getValues();
      const metadataData = metadataForm.getValues();
      const articleFilesData = articleFilesForm.getValues();
      const childArticlesData = childrenSchema.parse(childArticlesForm.getValues());
      const parsedMetadata = metadataSchema.parse(metadataData);

      // Upload files first
      const uploadedFiles = await Promise.all(
        articleFilesData.articleFiles.map(async (fileData) => {
          if (!fileData.file || !(fileData.file instanceof File)) {
            throw new Error('File is missing');
          }

          const uploadedFile = await uploadMedia.mutateAsync({
            file: fileData.file,
            data: {},
          });
          return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
        }),
      );

      const basePayload = {
        ...detailsData,
        expiryDate: detailsData.expiryDate ? DateTime.fromJSDate(detailsData.expiryDate).toISO() : null,
        ...assignmentData,
        metadata: parsedMetadata,
        articleFiles: uploadedFiles,
      };

      if (templateData?.isContainer) {
        await createArticleWithChildren.mutateAsync({
          ...basePayload,
          children: childArticlesData,
        } as CreateArticleWithChildrenData);
      } else {
        await createArticle.mutateAsync(basePayload as Partial<Article>);
      }

      toast.success(t('articles.articleCreatedDescription'));
      queryClient.invalidateQueries({ queryKey: ['articles'] });

      if (orderId && orderData?.parent) {
        navigate(`/juh/order-management/orders/${orderData.parent}/sub-orders/${orderId}/article-rental`);
      } else {
        navigate('/juh/article-management/articles');
      }
    } catch (error) {
      if (!handleArticleError(error)) {
        toast.error(t('articles.errorSavingArticleDescription'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForms = async () => {
    const isDetailsValid = await detailsForm.trigger();
    const isAssignmentValid = await assignmentForm.trigger();
    const isMetadataValid = await metadataForm.trigger();
    const isArticleFilesValid = await articleFilesForm.trigger();

    return isDetailsValid && isAssignmentValid && isMetadataValid && isArticleFilesValid;
  };

  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null);

  if (templateData?.isContainer) {
    return (
      <StepperContext.Provider value={{ portalContainer, setPortalContainer }}>
        <PageHeader
          title={t('articles.newArticle')}
          backHref="/juh/article-management/articles"
          actions={<div ref={setPortalContainer} className="flex items-center gap-2" />}
        />
        <Stepper initialStep={0} steps={[{ label: t('articles.details') }, { label: t('contents.childArticles') }]}>
          <Step label={t('articles.articleDetails')}>
            <ArticleFormLayout>
              {/* Left Column */}
              <div className="col-span-8 space-y-6">
                <Form {...detailsForm}>
                  <ArticleDetailsCard control={detailsForm.control} disableExpiryDate={true} />
                </Form>

                <Form {...metadataForm}>
                  <ArticleMetadataCard
                    schema={metadataSchema}
                    form={metadataForm}
                    templateSelected={!!detailsForm.watch('template')}
                  />
                </Form>
              </div>

              {/* Right Column */}
              <div className="col-span-4 space-y-6">
                <Form {...assignmentForm}>
                  <ArticleAssignmentCard control={assignmentForm.control} disabled={!!orderId} />
                </Form>

                <Form {...articleFilesForm}>
                  <ArticleFilesCard />
                </Form>
              </div>
            </ArticleFormLayout>
          </Step>

          <Step label={t('contents.childArticles')}>
            <Form {...childArticlesForm}>
              <ArticleChildren
                template={templateData}
                form={childArticlesForm}
                onSchemaChange={handleChildrenSchemaChange}
              />
            </Form>
          </Step>

          <StepperFooter
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            validateForms={validateForms}
            childArticlesForm={childArticlesForm}
          />
        </Stepper>
      </StepperContext.Provider>
    );
  }

  return (
    <>
      <PageHeader
        title={t('articles.newArticle')}
        backHref="/juh/article-management/articles"
        actions={
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={() => navigate('/juh/article-management/articles')}>
              {t('common.discard')}
            </Button>
            <Button onClick={onSubmit} disabled={isSubmitting}>
              {isSubmitting ? t('common.creating') : t('common.create')}
            </Button>
          </div>
        }
      />
      <ArticleFormLayout>
        {/* Left Column */}
        <div className="col-span-8 space-y-6">
          <Form {...detailsForm}>
            <ArticleDetailsCard control={detailsForm.control} disableExpiryDate={false} />
          </Form>

          <Form {...metadataForm}>
            <ArticleMetadataCard
              schema={metadataSchema}
              form={metadataForm}
              templateSelected={!!detailsForm.watch('template')}
            />
          </Form>
        </div>

        {/* Right Column */}
        <div className="col-span-4 space-y-6">
          <Form {...assignmentForm}>
            <ArticleAssignmentCard control={assignmentForm.control} disabled={!!orderId} />
          </Form>

          <Form {...articleFilesForm}>
            <ArticleFilesCard />
          </Form>
        </div>
      </ArticleFormLayout>
    </>
  );
}

const StepperFooter = ({
  onSubmit,
  isSubmitting,
  validateForms,
  childArticlesForm,
}: {
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
  validateForms: () => Promise<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childArticlesForm: UseFormReturn<any>;
}) => {
  const { t } = useIntl();
  const { nextStep, prevStep, isLastStep, activeStep } = useStepper();
  const navigate = useNavigate();
  const { portalContainer } = useContext(StepperContext);

  const handleNextStep = async () => {
    if (activeStep === 0) {
      const isValid = await validateForms();
      if (isValid) {
        nextStep();
      }
    }
  };

  const handleSubmit = async () => {
    const isChildrenValid = await childArticlesForm.trigger();
    if (isChildrenValid) {
      await onSubmit();
    } else {
      toast.error(t('articles.childArticleValidation.title'), {
        description: t('articles.childArticleValidation.description'),
      });
    }
  };

  if (!portalContainer) return null;

  return createPortal(
    <div className="flex items-center gap-2">
      <Button variant="outline" onClick={() => navigate('/juh/article-management/articles')}>
        {t('common.discard')}
      </Button>
      <Button disabled={activeStep === 0 || isSubmitting} onClick={prevStep} variant="outline">
        {t('common.previous')}
      </Button>
      <Button onClick={isLastStep ? handleSubmit : handleNextStep} disabled={isSubmitting}>
        {isLastStep ? (isSubmitting ? t('common.creating') : t('common.create')) : t('common.next')}
      </Button>
    </div>,
    portalContainer,
  );
};
