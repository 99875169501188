import { useDeleteOrder, useOrder } from '@/core/api';
import { useAuth } from '@/core/auth';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  EmptyTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import type { Order } from '@johanniter-offshore/backend';
import type { ColumnDef } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { ArrowRight, MoreHorizontal } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

const ActionCell = ({ order, onDelete }: { order: Order; onDelete: (order: Order) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const { id: parentId } = useParams();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';

  const handleEdit = () => {
    navigate(`${basePath}/${parentId}/sub-orders/${order.id}/${order.type}`);
  };

  if (!isJuhUser) {
    return (
      <Button variant="ghost" size="icon" onClick={handleEdit} aria-label={t('common.view')}>
        <ArrowRight className="size-4" />
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={handleEdit}>{t('common.edit')}</DropdownMenuItem>
        {isJuhAdmin && <DropdownMenuItem onClick={() => onDelete(order)}>{t('common.delete')}</DropdownMenuItem>}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface OrderSubOrdersProps {
  orders: Order[];
  isLoading?: boolean;
  readOnly?: boolean;
}

export function OrderSubOrders({ orders, isLoading, readOnly = false }: OrderSubOrdersProps) {
  const { t } = useIntl();
  const { id: parentId } = useParams();
  const { data: parentOrder } = useOrder(parentId!);
  const [orderToDelete, setOrderToDelete] = useState<Order | null>(null);

  const columns: ColumnDef<Order>[] = [
    {
      accessorKey: 'orderNumber',
      header: t('orders.orderNumber'),
      cell: ({ row }) => <div>{row.getValue('orderNumber')}</div>,
    },
    {
      accessorKey: 'title',
      header: t('orders.title'),
      cell: ({ row }) => <div>{row.getValue('title')}</div>,
    },
    {
      accessorKey: 'type',
      header: t('orders.orderType'),
      cell: ({ row }) => {
        const type = row.getValue('type') as string;
        return <Badge>{t(`orders.${type}`)}</Badge>;
      },
    },
    {
      accessorKey: 'startDate',
      header: t('orders.startDate'),
      cell: ({ row }) => {
        const date = row.getValue('startDate') as string;
        return <div>{date ? DateTime.fromISO(date).toFormat('dd.MM.yyyy') : '-'}</div>;
      },
    },
    {
      accessorKey: 'endDate',
      header: t('orders.endDate'),
      cell: ({ row }) => {
        const date = row.getValue('endDate') as string;
        return <div>{date ? DateTime.fromISO(date).toFormat('dd.MM.yyyy') : '-'}</div>;
      },
    },
    {
      accessorKey: 'createdAt',
      header: t('common.createdAt'),
      cell: ({ row }) => {
        const date = row.getValue('createdAt') as string;
        return <div>{DateTime.fromISO(date).toFormat('dd.MM.yyyy')}</div>;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell order={row.original} onDelete={setOrderToDelete} />,
    },
  ];

  const table = useReactTable({
    data: orders ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const deleteOrder = useDeleteOrder();

  const confirmDeleteOrder = async () => {
    if (orderToDelete) {
      try {
        await deleteOrder.mutateAsync(orderToDelete.id);
        toast.success(t('orders.orderDeletedSuccessfully'));
        setOrderToDelete(null);
      } catch (error) {
        toast.error(t('orders.errorDeletingOrder'));
      }
    }
  };

  if (!parentOrder) {
    return null;
  }

  return (
    <>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t('common.loading')}
              </TableCell>
            </TableRow>
          ) : table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyTableRow colSpan={columns.length} />
          )}
        </TableBody>
      </Table>

      {!readOnly && (
        <AlertDialog open={!!orderToDelete} onOpenChange={(open) => !open && setOrderToDelete(null)}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t('orders.deleteOrder')}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('orders.deleteOrderConfirmation', { name: orderToDelete?.orderNumber || '' })}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
              <AlertDialogAction onClick={confirmDeleteOrder}>{t('common.delete')}</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}
