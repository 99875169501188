import { TableCell, TableRow } from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';

interface TableLoadingCellProps {
  colSpan: number;
}

export const TableLoadingCell = ({ colSpan }: TableLoadingCellProps) => {
  const { t } = useIntl();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} className="h-[49px] text-center">
        <div className="flex items-center justify-center gap-2">
          <div className="size-3 border-2 border-muted-foreground/30 border-t-muted-foreground animate-spin rounded-full" />
          <span className="text-muted-foreground">{t('common.loading')}</span>
        </div>
      </TableCell>
    </TableRow>
  );
};
