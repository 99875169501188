import { FormControl, FormItem, FormMessage } from '../../form';
import { Input } from '../../input';
import AutoFormLabel from '../common/label';
import AutoFormTooltip from '../common/tooltip';
import type { AutoFormInputComponentProps } from '../types';

export default function AutoFormNumber({
  label,
  isRequired,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  const { showLabel: _showLabel, ...fieldPropsWithoutShowLabel } = fieldProps;

  const showLabel = _showLabel === undefined ? true : _showLabel;

  return (
    <FormItem className="flex w-full flex-col justify-start">
      {showLabel && <AutoFormLabel label={fieldConfigItem?.label || label} isRequired={isRequired} />}
      <FormControl>
        <Input
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          onKeyDown={(e) => {
            // Allow: backspace, delete, tab, escape, enter, decimal point
            if (
              e.key === 'Backspace' ||
              e.key === 'Delete' ||
              e.key === 'Tab' ||
              e.key === 'Escape' ||
              e.key === 'Enter' ||
              e.key === '.' ||
              e.key === 'ArrowLeft' ||
              e.key === 'ArrowRight' ||
              e.key === 'ArrowUp' ||
              e.key === 'ArrowDown'
            ) {
              return;
            }
            // Ensure that it is a number and stop the keypress if it's not
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            // Remove any non-numeric characters (except decimal point)
            e.target.value = e.target.value.replace(/[^\d.]/g, '');
            // Ensure only one decimal point
            const decimalCount = (e.target.value.match(/\./g) || []).length;
            if (decimalCount > 1) {
              e.target.value = e.target.value.replace(/\.(?=.*\.)/g, '');
            }
            fieldPropsWithoutShowLabel.onChange?.(e);
          }}
          {...fieldPropsWithoutShowLabel}
        />
      </FormControl>
      <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
      <FormMessage />
    </FormItem>
  );
}
