import { cn } from '@/shared/utils';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronRight, Edit, Trash } from 'lucide-react';
import React from 'react';

export interface TreeDataItem {
  id: string;
  name: string;
  children?: TreeDataItem[];
}

export type TreeProps = React.HTMLAttributes<HTMLDivElement> & {
  data: TreeDataItem[] | TreeDataItem;
  onEditTreeItem?: (item: TreeDataItem) => void;
  onDeleteTreeItem?: (item: TreeDataItem) => void;
  expandAll?: boolean;
  disableActions?: boolean;
};

const Tree = React.forwardRef<HTMLDivElement, TreeProps>(
  ({ data, onEditTreeItem, onDeleteTreeItem, expandAll, disableActions, className, ...props }, ref) => {
    const expandedItemIds = React.useMemo(() => {
      if (!expandAll) {
        return [] as string[];
      }

      const ids: string[] = [];

      function walkTreeItems(items: TreeDataItem[] | TreeDataItem) {
        if (items instanceof Array) {
          for (const item of items) {
            ids.push(item.id);
            if (item.children) {
              walkTreeItems(item.children);
            }
          }
        } else if (items.children) {
          walkTreeItems(items.children);
        }
      }

      walkTreeItems(data);
      return ids;
    }, [data, expandAll]);

    return (
      <div className={cn('overflow-hidden', className)}>
        <div className="bg-muted/20 relative rounded-b">
          <TreeItem
            data={data}
            ref={ref}
            onEditTreeItem={onEditTreeItem}
            onDeleteTreeItem={onDeleteTreeItem}
            expandedItemIds={expandedItemIds}
            disableActions={disableActions}
            {...props}
          />
        </div>
      </div>
    );
  },
);
Tree.displayName = 'Tree';

type TreeItemProps = TreeProps & {
  expandedItemIds: string[];
  isNested?: boolean;
};

const TreeItem = React.forwardRef<HTMLDivElement, TreeItemProps>(
  (
    { className, data, onEditTreeItem, onDeleteTreeItem, expandedItemIds, disableActions, isNested = false, ...props },
    ref,
  ) => {
    return (
      <div ref={ref} role="tree" className={className} {...props}>
        <ul>
          {data instanceof Array ? (
            <>
              {data.map((item) => (
                <li key={item.id} className={`${data.indexOf(item) === data.length - 1 ? '' : 'border-b'}`}>
                  {item.children?.length ? (
                    <AccordionPrimitive.Root type="multiple" defaultValue={expandedItemIds}>
                      <AccordionPrimitive.Item value={item.id}>
                        <AccordionTrigger
                          className={cn(
                            `
                              pl-3 pr-2
                              hover:before:opacity-100
                              before:absolute before:left-0 before:w-full before:opacity-0 before:bg-muted/80 before:h-[1.75rem] before:-z-10
                              bg-card
                            `,
                            '[&[data-state=open]]:border-b [&[data-state=open]]:border-b-border',
                            isNested && 'border-l border-l-border',
                            !isNested &&
                              data.indexOf(item) === data.length - 1 &&
                              '[&[data-state=closed]]:rounded-b-lg',
                          )}
                        >
                          <span className="truncate text-sm">{item.name}</span>
                          <EditDeleteButtons
                            onEdit={onEditTreeItem ? () => onEditTreeItem(item) : undefined}
                            onDelete={onDeleteTreeItem ? () => onDeleteTreeItem(item) : undefined}
                            disabled={disableActions}
                          />
                        </AccordionTrigger>
                        <AccordionContent className="pl-12">
                          <TreeItem
                            data={item.children ? item.children : item}
                            onEditTreeItem={onEditTreeItem}
                            onDeleteTreeItem={onDeleteTreeItem}
                            expandedItemIds={expandedItemIds}
                            disableActions={disableActions}
                            isNested={true}
                          />
                        </AccordionContent>
                      </AccordionPrimitive.Item>
                    </AccordionPrimitive.Root>
                  ) : (
                    <Leaf
                      item={item}
                      onEdit={onEditTreeItem}
                      onDelete={onDeleteTreeItem}
                      disabled={disableActions}
                      className={cn(
                        isNested && 'border-l',
                        'bg-card',
                        !isNested && data.indexOf(item) === data.length - 1 && 'rounded-b-lg',
                      )}
                    />
                  )}
                </li>
              ))}
            </>
          ) : (
            <li>
              <Leaf
                item={data}
                onEdit={onEditTreeItem}
                onDelete={onDeleteTreeItem}
                disabled={disableActions}
                className={cn(isNested && 'border-l', 'bg-card')}
              />
            </li>
          )}
        </ul>
      </div>
    );
  },
);
TreeItem.displayName = 'TreeItem';

const EditDeleteButtons: React.FC<{
  onEdit?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
}> = ({ onEdit, onDelete, disabled }) => (
  <div className="ml-auto flex">
    {onEdit && (
      <span
        role="button"
        tabIndex={disabled ? -1 : 0}
        className={cn(
          `
            ring-offset-background
            focus-visible:ring-ring
            hover:bg-accent hover:text-accent-foreground
            inline-flex size-8 items-center justify-center rounded-md p-0 text-sm font-medium transition-colors
            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
          `,
          disabled && 'pointer-events-none opacity-50',
        )}
        onClick={(e) => {
          if (!disabled) {
            e.stopPropagation();
            onEdit();
          }
        }}
        onKeyDown={(e) => {
          if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            e.stopPropagation();
            onEdit();
          }
        }}
      >
        <Edit className="size-4" />
      </span>
    )}
    {onDelete && (
      <span
        role="button"
        tabIndex={disabled ? -1 : 0}
        className={cn(
          `
            ring-offset-background
            focus-visible:ring-ring
            hover:bg-accent hover:text-accent-foreground
            inline-flex size-8 items-center justify-center rounded-md p-0 text-sm font-medium transition-colors
            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
          `,
          disabled && 'pointer-events-none opacity-50',
        )}
        onClick={(e) => {
          if (!disabled) {
            e.stopPropagation();
            onDelete();
          }
        }}
        onKeyDown={(e) => {
          if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }
        }}
      >
        <Trash className="size-4" />
      </span>
    )}
  </div>
);

const Leaf = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    item: TreeDataItem;
    onEdit?: (item: TreeDataItem) => void;
    onDelete?: (item: TreeDataItem) => void;
    disabled?: boolean;
  }
>(({ className, item, onEdit, onDelete, disabled, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        `
          flex items-center p-2
          hover:before:opacity-100
          before:absolute before:left-0 before:right-1 before:w-full before:opacity-0 before:bg-muted/80 before:h-[1.75rem] before:-z-10
        `,
        className,
      )}
      {...props}
    >
      <span className="ml-2 grow truncate text-sm">{item.name}</span>
      <EditDeleteButtons
        onEdit={onEdit ? () => onEdit(item) : undefined}
        onDelete={onDelete ? () => onDelete(item) : undefined}
        disabled={disabled}
      />
    </div>
  );
});
Leaf.displayName = 'Leaf';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 w-full items-center p-2 transition-all first:[&[data-state=open]>svg]:rotate-90',
        className,
      )}
      {...props}
    >
      <ChevronRight className="text-muted-foreground mr-2 size-4 shrink-0 transition-transform duration-200" />
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className,
    )}
    {...props}
  >
    <div>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Tree };
