import { payloadClient } from '@/core/api';
import { useUpdateUser, useUsers } from '@/core/api';
import { useCreateInvite, useDeleteInvite, useInvites, useUpdateInvite } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui';
import type { Invite, User } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { UserForm, type UserFormValues } from '../components/user-form';
import { UserTable } from '../components/user-table';

interface PageProps {
  allowedRoles: ('customer-admin' | 'customer-employee' | 'juh-admin' | 'juh-employee')[];
  organizationId?: string;
  hideInviteButton?: boolean;
  title?: string;
}

export function UserManagement({ allowedRoles, organizationId, hideInviteButton, title }: PageProps) {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const [page] = useState(1);

  const onlyReadPermissions = currentUser?.role === 'customer-employee' || currentUser?.role === 'juh-employee';

  const { data: usersData, isLoading: isLoadingUsers } = useUsers({
    page,
    limit: 0,
    sort: 'createdAt',
    where: {
      role: {
        in: allowedRoles,
      },
      ...(organizationId
        ? { organization: { equals: organizationId } }
        : currentUser?.organization
          ? { organization: { equals: currentUser.organization } }
          : { organization: { exists: false } }),
    },
  });

  const { data: invitesData, isLoading: isLoadingInvites } = useInvites({
    page,
    limit: 0,
    sort: 'createdAt',
    where: {
      ...(organizationId
        ? { organization: { equals: organizationId } }
        : currentUser?.organization
          ? { organization: { equals: currentUser.organization } }
          : { organization: { exists: false } }),
    },
    disabled: onlyReadPermissions,
  });

  const [selectedUser, setSelectedUser] = useState<User | (Invite & { isInvite: true }) | undefined>(undefined);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const updateUser = useUpdateUser();
  const updateInvite = useUpdateInvite();
  const createInvite = useCreateInvite();
  const deleteInvite = useDeleteInvite();

  const handleEditUser = (user: User | (Invite & { isInvite: true })) => {
    if (!onlyReadPermissions) {
      setSelectedUser(user);
      setIsEditDialogOpen(true);
    }
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedUser(undefined);

    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 200);
  };

  const handleSubmit = async (data: UserFormValues) => {
    if (!allowedRoles.includes(data.role)) {
      toast.error(t('errors.invalidRole'));
      return;
    }

    if (onlyReadPermissions) return;

    if (selectedUser) {
      if ('isInvite' in selectedUser && selectedUser.isInvite) {
        await updateInvite.mutateAsync({
          id: selectedUser.id,
          data: {
            ...data,
            email: undefined,
            organization: organizationId,
          },
        });
        toast.success(t('users.userUpdatedSuccessfully'));
      } else {
        await updateUser.mutateAsync({
          id: selectedUser.id,
          data: {
            ...data,
            language: undefined,
            email: undefined,
            organization: organizationId,
          },
        });
        toast.success(t('users.userUpdatedSuccessfully'));
      }

      handleCloseEditDialog();
    } else {
      await createInvite.mutateAsync({
        ...data,
        organization: organizationId,
      });
      setIsInviteDialogOpen(false);
      toast.success(t('users.invitationSentSuccessfully'));
    }
  };

  const handleDelete = async (user: User | (Invite & { isInvite: true })) => {
    if (onlyReadPermissions) return;

    if ('isInvite' in user && user.isInvite) {
      await deleteInvite.mutateAsync(user.id);
      toast.success(t('users.invitationDeletedSuccessfully'));
    }
  };

  const handleResend = async (invite: User | (Invite & { isInvite: true })) => {
    if (onlyReadPermissions) return;

    const toastId = toast.loading(t('users.resendingInvitation'));
    try {
      await payloadClient.customRequest({
        subpath: '/invites/resend-invite',
        method: 'POST',
        data: { inviteId: invite.id },
      });
      toast.success(t('users.invitationResentSuccessfully'), { id: toastId });
    } catch (error) {
      toast.error(t('errors.generalError'), { id: toastId });
    }
  };

  const isLoading = isLoadingUsers || (!onlyReadPermissions && isLoadingInvites);

  const combinedData = [
    ...(usersData?.docs ?? []),
    ...(!onlyReadPermissions
      ? (invitesData?.docs?.map((invite: Invite) => ({
          ...invite,
          isInvite: true,
        })) ?? [])
      : []),
  ];

  const inviteButton = (
    <Dialog open={isInviteDialogOpen} onOpenChange={setIsInviteDialogOpen}>
      <DialogTrigger asChild>
        <Button>
          <PlusCircle className="size-4 mr-2" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('users.inviteUser')}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('users.inviteUser')}</DialogTitle>
          <DialogDescription>{t('users.inviteUserDescription')}</DialogDescription>
        </DialogHeader>
        <UserForm onSubmit={handleSubmit} allowedRoles={allowedRoles} />
      </DialogContent>
    </Dialog>
  );

  return (
    <div className="space-y-6">
      {!onlyReadPermissions && !hideInviteButton && (
        <div className="flex items-center justify-between">
          <PageHeading>{title || t('users.users')}</PageHeading>
          {inviteButton}
        </div>
      )}

      <UserTable
        users={combinedData}
        onEdit={handleEditUser}
        onDelete={handleDelete}
        onResend={handleResend}
        isLoading={isLoading}
      />

      {!onlyReadPermissions && (
        <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('users.editUser')}</DialogTitle>
              <DialogDescription>{t('users.editUserDescription')}</DialogDescription>
            </DialogHeader>
            <UserForm user={selectedUser} onSubmit={handleSubmit} allowedRoles={allowedRoles} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
