import type { Media } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

export const useMedia = (params?: {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Media | `-${keyof Media}`;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['media', params],
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'media',
        ...params,
      });
      return response;
    },
  });
};

export const useMediaItem = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: ['media', id, params],
    enabled: !params?.disabled && !!id,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'media',
        id,
      });
      return response;
    },
  });
};

export const useUploadMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, data }: { file: File; data?: Partial<Media> }) => {
      const response = await payloadClient.create({
        collection: 'media',
        data: data || {},
        file,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['media'] });
    },
  });
};

export const useUpdateMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Media> }) => {
      const response = await payloadClient.updateById({
        collection: 'media',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['media'] });
    },
  });
};

export const useDeleteMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'media',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['media'] });
    },
  });
};
