import { FileUploadCard } from '@/shared/components/uploads';
import { useIntl } from '@tiny-intl/react';
import { useFormContext } from 'react-hook-form';

import type { TemplateFilesFormValues } from '../types';

interface TemplateFilesProps {
  disabled?: boolean;
}

export function TemplateFilesCard({ disabled = false }: TemplateFilesProps) {
  const { t } = useIntl();
  useFormContext<TemplateFilesFormValues>();

  return (
    <FileUploadCard
      disabled={disabled}
      title={t('templates.templateFiles')}
      description={t('templates.templateFilesDescription')}
      fieldArrayName="files"
    />
  );
}
