import { AuthProvider } from '@/core/auth';
import { ThemeProvider } from '@/core/theme';
import { Toaster, TooltipProvider } from '@/shared/components/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type ReactNode } from 'react';

import { I18nProvider } from './i18n-provider';

const queryClient = new QueryClient();

interface RootProviderProps {
  children: ReactNode;
}

export function RootProvider({ children }: RootProviderProps) {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <I18nProvider>
            <TooltipProvider>
              {children}
              <Toaster duration={2000} />
            </TooltipProvider>
          </I18nProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
