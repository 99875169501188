import { RootProvider } from './core/providers';
import { AppRouter } from './core/router';

export function App() {
  return (
    <RootProvider>
      <AppRouter />
    </RootProvider>
  );
}
