import { useGlobalMetadata } from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { SearchCombobox } from '@/shared/components/inputs';
import { MetadataAutoForm } from '@/shared/components/metadata/metadata-auto-form';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  jsonSchemaToFormFields,
} from '@/shared/components/metadata/metadata-builder';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  DatePicker,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Textarea,
} from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import jsonSchemaToZod from 'json-schema-to-zod';
import { Edit3 } from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import type { OrderFormData } from '../schema';
import { createOrderSchema } from '../schema';

function parseMetadata(metadata?: Record<string, unknown>): Record<string, unknown> {
  if (!metadata) return {};

  const entries = Object.entries(metadata).sort((a, b) => a[0].localeCompare(b[0]));

  return Object.fromEntries(
    entries.map(([key, value]) => {
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
        return [key, DateTime.fromISO(value).toJSDate()];
      }
      return [key, value];
    }),
  );
}

interface OrderFormProps {
  id?: string;
  defaultValues?: Partial<Order>;
  onSubmit: (data: OrderFormData) => Promise<void>;
  readOnlyAssignments?: boolean;
  error?: PayloadApiError;
  orderType?: 'root-order' | 'article-rental';
  readOnly?: boolean;
}

export function OrderForm({
  id,
  defaultValues,
  onSubmit,
  readOnlyAssignments,
  error,
  orderType = 'root-order',
  readOnly = false,
}: OrderFormProps) {
  const { t } = useIntl();
  const { data: globalMetadata } = useGlobalMetadata();
  const [metadataSchema, setMetadataSchema] = useState<z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>>(
    z.object({}),
  );
  const [isMetadataDrawerOpen, setIsMetadataDrawerOpen] = useState(false);
  const [dynamicMetadataSchema, setDynamicMetadataSchema] = useState<
    z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>
  >(z.object({}));

  const form = useForm<OrderFormData>({
    resolver: zodResolver(createOrderSchema(t, metadataSchema, dynamicMetadataSchema)),
    defaultValues: {
      orderNumber: defaultValues?.orderNumber || '',
      title: defaultValues?.title || '',
      organization: (defaultValues?.organization as string) || '',
      object: (defaultValues?.object as string) || null,
      startDate: defaultValues?.startDate || '',
      endDate: defaultValues?.endDate || '',
      description: defaultValues?.description || '',
      metadata: parseMetadata(defaultValues?.metadata as Record<string, unknown>),
      dynamicMetadataSchema: defaultValues?.dynamicMetadataSchema || undefined,
      dynamicMetadataValues: parseMetadata(defaultValues?.dynamicMetadataValues as Record<string, unknown>),
    },
  });

  // Update form values when defaultValues changes (e.g. when React Query refetches)
  useEffect(() => {
    if (defaultValues) {
      form.reset({
        orderNumber: defaultValues.orderNumber || '',
        title: defaultValues.title || '',
        organization: (defaultValues.organization as string) || '',
        object: (defaultValues.object as string) || null,
        startDate: defaultValues.startDate || '',
        endDate: defaultValues.endDate || '',
        description: defaultValues.description || '',
        metadata: parseMetadata(defaultValues.metadata as Record<string, unknown>),
        dynamicMetadataSchema: defaultValues.dynamicMetadataSchema || undefined,
        dynamicMetadataValues: parseMetadata(defaultValues.dynamicMetadataValues as Record<string, unknown>),
      });
    }
  }, [defaultValues, form]);

  useEffect(() => {
    if (globalMetadata) {
      const metadataConfig =
        orderType === 'root-order' ? globalMetadata.rootOrderMetadata : globalMetadata.articleRentalOrderMetadata;

      if (metadataConfig) {
        const zodSchemaString = jsonSchemaToZod(metadataConfig);
        const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
        const zodSchema = eval(transformedSchema);
        setMetadataSchema(zodSchema);
      }
    }
  }, [globalMetadata, orderType]);

  useEffect(() => {
    if (defaultValues?.dynamicMetadataSchema) {
      const zodSchemaString = jsonSchemaToZod(defaultValues.dynamicMetadataSchema);
      const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
      const zodSchema = eval(transformedSchema);
      setDynamicMetadataSchema(zodSchema);
    }
  }, [defaultValues?.dynamicMetadataSchema]);

  const metadataBuilderForm = useForm({
    defaultValues: {
      metadata: defaultValues?.dynamicMetadataSchema ? jsonSchemaToFormFields(defaultValues.dynamicMetadataSchema) : [],
    },
  });

  const handleMetadataBuilderSubmit = async (data: {
    metadata: Array<{
      key: string;
      label: string;
      type: 'string' | 'number' | 'boolean' | 'date';
      required: boolean;
    }>;
  }) => {
    const jsonSchema = formFieldsToJsonSchema(data.metadata);

    form.setValue('dynamicMetadataSchema', jsonSchema);

    // Convert to Zod schema and update state
    const zodSchemaString = jsonSchemaToZod(jsonSchema);
    const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
    const zodSchema = eval(transformedSchema);
    setDynamicMetadataSchema(zodSchema);

    metadataBuilderForm.setValue('metadata', jsonSchemaToFormFields(jsonSchema));

    setIsMetadataDrawerOpen(false);
  };

  // Handle backend validation errors
  useEffect(() => {
    if (error?.response?.errors) {
      (error.response.errors as Array<{ message: string }>).forEach((err) => {
        if (err.message === 'OrderStartDateBeforeParent' || err.message === 'OrderChildStartDateBeforeParent') {
          form.setError('startDate', { message: t(`validation.${err.message}`) });
        }
        if (err.message === 'OrderEndDateAfterParent' || err.message === 'OrderChildEndDateAfterParent') {
          form.setError('endDate', { message: t(`validation.${err.message}`) });
        }
      });
    }
  }, [error, form, t]);

  return (
    <>
      <Form {...form}>
        <form id={id} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-6">
            <div className="grid grid-cols-12 gap-6">
              {/* Main Details Card */}
              <div className="col-span-9 space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.orderDetails')}</CardTitle>
                    <CardDescription>{t('orders.orderDetailsDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div className="grid grid-cols-2 gap-6">
                      <FormField
                        control={form.control}
                        name="orderNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel required>{t('orders.orderNumber')}</FormLabel>
                            <FormControl>
                              <Input {...field} disabled={readOnly} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel required>{t('orders.title')}</FormLabel>
                            <FormControl>
                              <Input {...field} disabled={readOnly} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('orders.description')}</FormLabel>
                          <FormControl>
                            <Textarea {...field} disabled={readOnly} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                {/* Metadata Card */}
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0">
                    <div className="space-y-1">
                      <CardTitle>{t('metadata.metadata')}</CardTitle>
                      <CardDescription>{t('orders.metadataCardDescription')}</CardDescription>
                    </div>
                    {!readOnly && (
                      <Button
                        variant="outline"
                        size="sm"
                        className="ml-auto"
                        onClick={() => setIsMetadataDrawerOpen(true)}
                        type="button"
                      >
                        <Edit3 className="!size-3" />
                        {t('metadata.addCustomMetadata')}
                      </Button>
                    )}
                  </CardHeader>
                  <CardContent className="space-y-6">
                    {Object.keys(metadataSchema.shape || {}).length > 0 ? (
                      <MetadataAutoForm schema={metadataSchema} form={form} path={['metadata']} disabled={readOnly} />
                    ) : (
                      <div className="text-sm text-muted-foreground">({t('metadata.noOrderMetadata')})</div>
                    )}

                    {form.watch('dynamicMetadataSchema') &&
                      Object.keys(form.watch('dynamicMetadataSchema.properties') || {}).length > 0 && (
                        <div>
                          <h4 className="mb-4 text-sm font-medium">{t('metadata.customMetadata')}</h4>
                          <MetadataAutoForm
                            schema={dynamicMetadataSchema}
                            form={form}
                            path={['dynamicMetadataValues']}
                            disabled={readOnly}
                          />
                        </div>
                      )}
                  </CardContent>
                </Card>
              </div>

              {/* Right Column */}
              <div className="col-span-3 space-y-6">
                {/* Organization Card */}
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.organizationAssignment')}</CardTitle>
                    <CardDescription>{t('orders.organizationAssignmentDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <FormLabel required>{t('organization.organization')}</FormLabel>
                    <FormField
                      control={form.control}
                      name="organization"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <SearchCombobox
                              value={field.value || undefined}
                              onSelect={field.onChange}
                              collectionKey="organizations"
                              searchKey="name"
                              disabled={readOnly || readOnlyAssignments}
                              texts={{
                                selectItemMsg: t('orders.selectOrganization'),
                                searchPlaceholder: t('orders.searchOrganization'),
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                {/* Object Assignment Card */}
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.objectAssignment')}</CardTitle>
                    <CardDescription>{t('orders.objectAssignmentDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <FormLabel>{t('objects.objects')}</FormLabel>
                    <FormField
                      control={form.control}
                      name="object"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <SearchCombobox
                              value={field.value || undefined}
                              onSelect={field.onChange}
                              collectionKey="objects"
                              searchKey="name"
                              disabled={readOnly || readOnlyAssignments}
                              texts={{
                                selectItemMsg: t('orders.selectObject'),
                                searchPlaceholder: t('orders.searchObject'),
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                {/* Dates Card */}
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.orderDates')}</CardTitle>
                    <CardDescription>{t('orders.orderDatesDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <FormField
                      control={form.control}
                      name="startDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>{t('orders.startDate')}</FormLabel>
                          <FormControl>
                            <DatePicker
                              date={field.value ? new Date(field.value) : undefined}
                              setDate={(date) => field.onChange(date?.toISOString() || '')}
                              placeholder={t('common.selectOption')}
                              defaultMonth={field.value ? new Date(field.value) : undefined}
                              disabled={readOnly}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="endDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>{t('orders.endDate')}</FormLabel>
                          <FormControl>
                            <DatePicker
                              date={field.value ? new Date(field.value) : undefined}
                              setDate={(date) => field.onChange(date?.toISOString() || '')}
                              placeholder={t('common.selectOption')}
                              defaultMonth={field.value ? new Date(field.value) : undefined}
                              disabled={readOnly}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </form>
      </Form>

      <Drawer open={isMetadataDrawerOpen} onOpenChange={setIsMetadataDrawerOpen} direction="right">
        <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none max-w-[800px]">
          <ScrollArea className="h-screen">
            <DrawerHeader>
              <DrawerTitle>{t('metadata.customMetadataBuilder')}</DrawerTitle>
              <DrawerDescription>{t('metadata.customMetadataBuilderDescription')}</DrawerDescription>
            </DrawerHeader>
            <Form {...metadataBuilderForm}>
              <form onSubmit={metadataBuilderForm.handleSubmit(handleMetadataBuilderSubmit)}>
                <div className="space-y-4 p-4">
                  <MetadataBuilder />
                </div>
                <DrawerFooter>
                  <Button type="submit">{t('common.save')}</Button>
                  <DrawerClose asChild>
                    <Button type="button" variant="outline">
                      {t('common.cancel')}
                    </Button>
                  </DrawerClose>
                </DrawerFooter>
              </form>
            </Form>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
    </>
  );
}
