import {
  useArticle,
  useResolvedTemplateMetadata,
  useTemplate,
  useUpdateArticle,
  useUpdateArticleChildren,
  useUploadMedia,
} from '@/core/api';
import { TabNav } from '@/shared/components/layout';
import { PageHeader } from '@/shared/components/layout/page-header';
import { Button, Form } from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Article } from '@johanniter-offshore/backend';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import jsonSchemaToZod from 'json-schema-to-zod';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import { ArticleAssignmentCard, ArticleDetailsCard, ArticleFilesCard, ArticleMetadataCard } from '../components';
import { ArticleChildren } from '../components/article-children';
import { useArticleErrorHandling } from '../hooks';
import { articleDetailsSchema, articleFilesSchema, assignmentSchema } from '../schema';
import type { ArticleDetailsFormValues, ArticleFilesFormValues, AssignmentFormValues } from '../types';

function ArticleFormLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-12 gap-6">{children}</div>
    </div>
  );
}

function parseDate(value: string | null | undefined): Date | null {
  return value ? new Date(value) : null;
}

function parseMetadata(metadata?: Record<string, unknown>): Record<string, unknown> {
  if (!metadata) return {};

  const entries = Object.entries(metadata).sort((a, b) => a[0].localeCompare(b[0]));

  return Object.fromEntries(
    entries.map(([key, value]) => {
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
        return [key, DateTime.fromISO(value).toJSDate()];
      }
      return [key, value];
    }),
  );
}

export function EditArticle() {
  const { t } = useIntl();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const updateArticle = useUpdateArticle();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleArticleError } = useArticleErrorHandling();
  const uploadMedia = useUploadMedia();
  const updateArticleChildren = useUpdateArticleChildren();

  const { data: articleData } = useArticle(id as string);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [metadataSchema, setMetadataSchema] = useState<z.ZodObject<any, any>>(z.object({}));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [childArticlesSchema, setChildArticlesSchema] = useState<z.ZodType<any>>(z.object({}));

  const detailsForm = useForm<ArticleDetailsFormValues>({
    resolver: zodResolver(articleDetailsSchema),
    defaultValues: {
      template: '',
      expiryDate: null,
    },
  });

  const assignmentForm = useForm<AssignmentFormValues>({
    resolver: zodResolver(assignmentSchema),
    defaultValues: {
      order: null,
    },
  });

  const metadataForm = useForm({
    resolver: zodResolver(metadataSchema),
    defaultValues: {},
  });

  const childArticlesForm = useForm({
    resolver: zodResolver(childArticlesSchema),
    defaultValues: {},
  });

  const articleFilesForm = useForm<ArticleFilesFormValues>({
    resolver: zodResolver(articleFilesSchema),
    defaultValues: {
      articleFiles: [],
    },
  });

  const { data: templateData } = useTemplate(detailsForm.watch('template'), {
    disabled: !detailsForm.watch('template'),
  });
  const { data: templateMetadata } = useResolvedTemplateMetadata(templateData?.id);

  const handleChildrenSchemaChange = useCallback(
    (schema: z.ZodType) => {
      setChildArticlesSchema(schema);
      const currentValues = childArticlesForm.getValues();
      childArticlesForm.reset(currentValues);
    },
    [childArticlesForm],
  );

  useEffect(() => {
    if (templateMetadata) {
      const zodSchemaString = jsonSchemaToZod(templateMetadata);
      const transformedSchema = zodSchemaString.replace(/z\.string\(\)\.datetime\({ offset: true }\)/g, 'z.date()');
      const zodSchema = eval(transformedSchema);
      setMetadataSchema(zodSchema);
    }
  }, [templateMetadata]);

  useEffect(() => {
    if (articleData) {
      detailsForm.reset({
        template: articleData.template as string,
        expiryDate: parseDate(articleData.expiryDate),
      });

      assignmentForm.reset({
        order: (articleData.order as string) || null,
      });

      metadataForm.reset(parseMetadata(articleData.metadata as Record<string, unknown>));

      articleFilesForm.reset({
        articleFiles:
          articleData.articleFiles?.map((file) => ({
            name: file?.name || '',
            description: file.description || '',
            id: file.id || '',
            file: file.file as string,
          })) || [],
      });
    }
  }, [articleData, detailsForm, assignmentForm, metadataForm, childArticlesForm, articleFilesForm]);

  const onSubmit = async (
    formData: Partial<
      ArticleFilesFormValues &
        AssignmentFormValues &
        ArticleDetailsFormValues & {
          children: Record<string, string[]>;
        } & {
          metadata: Record<string, unknown>;
        }
    >,
  ) => {
    const payload: Partial<Article> = {};

    if (!templateData?.isContainer && formData.expiryDate !== undefined) {
      payload.expiryDate = formData.expiryDate?.toISOString() || null;
    }
    if (formData.metadata !== undefined) {
      payload.metadata = formData.metadata || null;
    }
    if (formData.order !== undefined) {
      payload.order = formData.order || null;
    }

    if (formData.articleFiles) {
      const existingFiles = articleData?.articleFiles || [];
      const newFiles = formData.articleFiles.filter(
        (file: ArticleFilesFormValues['articleFiles'][0]) => file.file instanceof File,
      );
      const updatedFiles = formData.articleFiles.filter(
        (file: ArticleFilesFormValues['articleFiles'][0]) => typeof file.id === 'string',
      );

      const uploadedFiles = await Promise.all(
        newFiles.map(async (fileData: ArticleFilesFormValues['articleFiles'][0]) => {
          if (!fileData.file || !(fileData.file instanceof File)) {
            throw new Error('File is missing');
          }
          const uploadedFile = await uploadMedia.mutateAsync({
            file: fileData.file,
            data: {},
          });
          return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
        }),
      );

      payload.articleFiles = [
        ...existingFiles
          .filter((file) =>
            updatedFiles.some((updatedFile: ArticleFilesFormValues['articleFiles'][0]) => updatedFile.id === file.file),
          )
          .map((file) => ({ ...file, file: file.file as string, name: file.name || '' })),
        ...updatedFiles.map((file: ArticleFilesFormValues['articleFiles'][0]) => ({
          ...file,
          file: file.file as string,
          name: file.name || '',
        })),
        ...uploadedFiles,
      ];
    }

    if (formData.children) {
      payload.children = Object.entries(formData.children)
        .map(([templateId, articleIds]) =>
          (articleIds as string[]).map((id) => ({ article: id, template: templateId })),
        )
        .flat();
    }

    return payload;
  };

  const handleSubmit = async () => {
    const isDetailsValid = await detailsForm.trigger();
    const isAssignmentValid = await assignmentForm.trigger();
    const isMetadataValid = await metadataForm.trigger();
    const isArticleFilesValid = await articleFilesForm.trigger();
    const isChildArticlesValid = templateData?.isContainer ? await childArticlesForm.trigger() : true;

    if (!isChildArticlesValid) {
      toast.error(t('articles.childArticleValidation.title'), {
        description: t('articles.childArticleValidation.description'),
      });
      return;
    }

    if (!isDetailsValid || !isAssignmentValid || !isMetadataValid || !isArticleFilesValid) {
      return;
    }

    setIsSubmitting(true);

    try {
      const detailsData = detailsForm.getValues();
      const assignmentData = assignmentForm.getValues();
      const { data: metadataData } = metadataSchema.safeParse(metadataForm.getValues());
      const articleFilesData = articleFilesForm.getValues();
      const childArticlesData = childArticlesForm.getValues();

      // Update main article data
      const payload = await onSubmit({
        ...detailsData,
        ...assignmentData,
        metadata: metadataData,
        articleFiles: articleFilesData.articleFiles,
      });

      // Update children if this is a container article
      if (templateData?.isContainer && id && location.pathname.endsWith('children')) {
        await updateArticleChildren.mutateAsync({
          id,
          data: childArticlesData,
        });
      }

      await updateArticle.mutateAsync({ id: id as string, data: payload });
      toast.success(t('articles.articleUpdatedDescription'));
      queryClient.invalidateQueries({ queryKey: ['articles'] });
    } catch (error) {
      if (!handleArticleError(error)) {
        toast.error(t('articles.errorSavingArticleDescription'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const tabs = [
    { value: 'details', label: t('articles.articleDetails') },
    { value: 'children', label: t('contents.childArticles') },
  ];

  const renderContent = () => {
    const currentTab = location.pathname.split('/').pop() || 'details';

    if (templateData?.isContainer) {
      switch (currentTab) {
        case 'details':
          return (
            <ArticleFormLayout>
              {/* Left Column */}
              <div className="col-span-8 space-y-6">
                <Form {...detailsForm}>
                  <ArticleDetailsCard
                    control={detailsForm.control}
                    disabled
                    article={articleData}
                    disableExpiryDate={true}
                  />
                </Form>

                <Form {...metadataForm}>
                  <ArticleMetadataCard
                    schema={metadataSchema}
                    form={metadataForm}
                    templateSelected={!!detailsForm.watch('template')}
                  />
                </Form>
              </div>

              {/* Right Column */}
              <div className="col-span-4 space-y-6">
                <Form {...assignmentForm}>
                  <ArticleAssignmentCard control={assignmentForm.control} disabled={Boolean(articleData?.parent)} />
                </Form>

                <Form {...articleFilesForm}>
                  <ArticleFilesCard />
                </Form>
              </div>
            </ArticleFormLayout>
          );
        case 'children':
          return (
            <Form {...childArticlesForm}>
              <ArticleChildren
                template={templateData}
                form={childArticlesForm}
                onSchemaChange={handleChildrenSchemaChange}
                articleId={id}
              />
            </Form>
          );
        default:
          return null;
      }
    }

    return (
      <ArticleFormLayout>
        {/* Left Column */}
        <div className="col-span-8 space-y-6">
          <Form {...detailsForm}>
            <ArticleDetailsCard
              control={detailsForm.control}
              disabled
              article={articleData}
              disableExpiryDate={false}
            />
          </Form>

          <Form {...metadataForm}>
            <ArticleMetadataCard
              schema={metadataSchema}
              form={metadataForm}
              templateSelected={!!detailsForm.watch('template')}
            />
          </Form>
        </div>

        {/* Right Column */}
        <div className="col-span-4 space-y-6">
          <Form {...assignmentForm}>
            <ArticleAssignmentCard control={assignmentForm.control} disabled={Boolean(articleData?.parent)} />
          </Form>

          <Form {...articleFilesForm}>
            <ArticleFilesCard />
          </Form>
        </div>
      </ArticleFormLayout>
    );
  };

  useEffect(() => {
    if (location.pathname.endsWith(id!)) {
      navigate(`/juh/article-management/articles/${id}/details`, { replace: true });
    }
  }, [location.pathname, id, navigate]);

  return (
    <>
      <PageHeader
        title={t('articles.editArticle')}
        backHref="/juh/article-management/articles"
        actions={
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={() => navigate('/juh/article-management/articles')}>
              {t('common.discard')}
            </Button>
            <Button onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? t('common.saving') : t('common.save')}
            </Button>
          </div>
        }
      />
      {templateData?.isContainer ? (
        <TabNav tabs={tabs} basePath={`/juh/article-management/articles/${id}`} rightContent={null} />
      ) : null}
      {renderContent()}
    </>
  );
}
