import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Object } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface ObjectFormProps {
  object?: Object;
  onSubmit: (data: ObjectFormValues) => Promise<void>;
}

export type ObjectFormValues = z.infer<typeof objectFormSchema>;

const objectFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
});

export const ObjectForm = ({ object, onSubmit }: ObjectFormProps) => {
  const { t } = useIntl();

  const form = useForm<ObjectFormValues>({
    resolver: zodResolver(objectFormSchema),
    defaultValues: {
      name: object?.name ?? '',
      description: object?.description ?? '',
    },
  });

  const handleSubmit = async (data: ObjectFormValues) => {
    await onSubmit(data);
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('common.name')}</FormLabel>
              <FormControl>
                <Input placeholder={t('objects.objectName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('common.description')}</FormLabel>
              <FormControl>
                <Textarea placeholder={t('objects.objectDescription')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{object ? t('common.save') : t('common.create')}</Button>
      </form>
    </Form>
  );
};
