import { useAuth } from '@/core/auth';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { createRoutes } from './routes';

export function AppRouter() {
  const auth = useAuth();

  if (auth.initialized) {
    const router = createBrowserRouter(createRoutes(auth.user));

    return <RouterProvider router={router} />;
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div
        className="text-primary size-4 animate-spin rounded-full border-2 border-current border-t-transparent"
        aria-label="loading"
      ></div>
    </div>
  );
}

export * from './navigation-routes';
