import { PayloadApiError, payloadClient } from '@/core/api';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
} from '@/shared/components/ui';
import z from '@/shared/utils/zod-translations';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export function AcceptInvite() {
  const { t, change, locale } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const [inviteData, setInviteData] = useState<{
    firstName: string;
    lastName: string;
    organization?: string;
    email: string;
    language: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inviteAccepted, setInviteAccepted] = useState<boolean>(false);
  const [tokenInvalidOrExpired, setTokenInvalidOrExpired] = useState<boolean>(false);

  const formSchema = z
    .object({
      password: z.string().min(8).max(256),
      passwordConfirmation: z.string().min(8).max(256),
    })
    .refine((data) => data.password === data.passwordConfirmation, {
      message: t('auth.invalidPasswordConfirmation'),
      path: ['passwordConfirmation'],
    });

  type AcceptInviteForm = z.infer<typeof formSchema>;

  const form = useForm<AcceptInviteForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  useEffect(() => {
    if (!token) {
      navigate('/auth/login');
      return;
    }

    const fetchInviteData = async () => {
      try {
        const response = await payloadClient.customRequest<{
          firstName: string;
          lastName: string;
          organization?: string;
          email: string;
          language: string;
        }>({
          subpath: '/invites/get-invite-data',
          method: 'POST',
          data: { token },
        });
        setInviteData(response);
        if (!localStorage.getItem('selectedLocale')) {
          localStorage.setItem('selectedLocale', response.language);
          change(response.language);
        }
      } catch (error) {
        setTokenInvalidOrExpired(true);
      }
    };

    fetchInviteData();
  }, [token, navigate, change]);

  async function onSubmit(values: AcceptInviteForm) {
    try {
      setIsLoading(true);
      await payloadClient.customRequest({
        subpath: '/invites/accept-invite',
        method: 'POST',
        data: { token, password: values.password, language: locale },
      });
      setInviteAccepted(true);
    } catch (error) {
      if (error instanceof PayloadApiError && error.statusCode === 401) {
        setTokenInvalidOrExpired(true);
      } else {
        form.setError('root', { message: t('errors.generalError') });
      }
    } finally {
      setIsLoading(false);
    }
  }

  if (tokenInvalidOrExpired) {
    return (
      <div className="space-y-4 text-center">
        <h1 className="text-3xl font-bold">{t('auth.invalidOrExpiredInvite')}</h1>
        <p>{t('auth.pleaseContactAdmin')}</p>
        <Link to="/auth/login" className="block text-sm underline">
          {t('auth.backToLogin')}
        </Link>
      </div>
    );
  }

  if (!inviteData) {
    return <div>{t('common.loading')}</div>;
  }

  const loginUrl = `/auth/login?email=${encodeURIComponent(inviteData.email)}`;

  return (
    <div>
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('auth.acceptInvite')}</h1>
        {!inviteAccepted && (
          <p
            className="text-muted-foreground text-balance"
            dangerouslySetInnerHTML={{
              __html: inviteData.organization
                ? t('auth.welcomeMessage', {
                    name: `${inviteData.firstName} ${inviteData.lastName}`,
                    organization: inviteData.organization,
                  })
                : t('auth.welcomeMessageNoOrg', {
                    name: `${inviteData.firstName} ${inviteData.lastName}`,
                  }),
            }}
          ></p>
        )}
      </div>
      <div className="mt-6 space-y-4">
        {!inviteAccepted && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.newPassword')}</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="passwordConfirmation"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.passwordConfirmation')}</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormMessage>{form.formState.errors.root?.message}</FormMessage>
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? t('auth.acceptingInvite') : t('auth.acceptInvite')}
              </Button>
            </form>
          </Form>
        )}

        {inviteAccepted && (
          <div className="space-y-4 text-center">
            <div className="text-sm">{t('auth.inviteAccepted')}</div>

            <Link className="block text-center text-sm underline" to={loginUrl}>
              {t('auth.goToLogin')}
            </Link>
          </div>
        )}

        <Separator className="my-4" />

        <Link className="block text-center text-sm underline" to={loginUrl}>
          {t('auth.alreadyHaveAccount')}
        </Link>
      </div>
    </div>
  );
}
