import { useArticles, useDeleteArticle, useOrder, useTemplate } from '@/core/api';
import { useAuth } from '@/core/auth';
import { SearchCombobox, SearchField } from '@/shared/components/inputs';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  EmptyTableRow,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { useDebounce } from '@/shared/hooks/use-debounce';
import type { Article } from '@johanniter-offshore/backend';
import type { ColumnDef, ColumnFiltersState, Row, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { Filter, ListFilter, MoreHorizontal, PlusCircle } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { DataTablePagination } from './data-table-navigation';
import { TableLoadingCell } from './table-loading-cell';

const TemplateCell = ({ templateId }: { templateId: string }) => {
  const { data: template, isLoading } = useTemplate(templateId);
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{template?.name || '-'}</div>;
};

const OrderCell = ({ orderId }: { orderId: string | null }) => {
  const { data: order, isLoading } = useOrder(orderId || '', { disabled: !orderId });
  if (!orderId) return <div>-</div>;
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{order?.orderNumber || '-'}</div>;
};

const ActionCell = ({ article, onDelete }: { article: Article; onDelete: (article: Article) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const isJuhAdmin = user?.role === 'juh-admin';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`/juh/article-management/articles/${article.id}`)}>
          {t('common.edit')}
        </DropdownMenuItem>
        <DropdownMenuItem disabled={!isJuhAdmin} onClick={() => onDelete(article)}>
          {t('common.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface ArticleTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  where?: Record<string, any>;
  hideFilters?: boolean;
  hideActions?: boolean;
  hideOrder?: boolean;
  customActions?: React.ReactNode;
  customCellActions?: (article: Article) => React.ReactNode;
}

export function ArticleTable({
  where = {},
  hideFilters = false,
  hideActions = false,
  hideOrder = false,
  customActions,
  customCellActions,
}: ArticleTableProps) {
  const { t } = useIntl();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'all' | 'container' | 'single'>('all');
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);

  const columns: ColumnDef<Article>[] = [
    {
      accessorKey: 'template',
      header: t('templates.template'),
      cell: ({ row }: { row: Row<Article> }) => <TemplateCell templateId={row.getValue('template') as string} />,
    },
    {
      accessorKey: 'expiryDate',
      header: t('articles.expiryDate'),
      cell: ({ row }: { row: Row<Article> }) => {
        const expiryDate = row.getValue('expiryDate') as string | null;
        if (!expiryDate) return <div>-</div>;
        const date = DateTime.fromISO(expiryDate);
        return <div>{date.toFormat('dd.MM.yyyy')}</div>;
      },
    },
    !hideOrder && {
      accessorKey: 'order',
      header: t('orders.order'),
      cell: ({ row }: { row: Row<Article> }) => <OrderCell orderId={row.getValue('order') as string} />,
    },
    !hideActions && {
      id: 'actions',
      cell: ({ row }: { row: Row<Article> }) =>
        customCellActions ? (
          customCellActions(row.original)
        ) : (
          <ActionCell article={row.original} onDelete={setArticleToDelete} />
        ),
    },
  ].filter(Boolean) as ColumnDef<Article>[];

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: articlesData, isLoading } = useArticles({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: {
      ...where,
      ...(activeTab === 'all'
        ? {}
        : {
            'template.isContainer': {
              equals: activeTab === 'container',
            },
          }),
      ...(selectedTemplateId
        ? {
            template: {
              equals: selectedTemplateId,
            },
          }
        : {}),
      ...(debouncedSearchTerm
        ? {
            or: [
              { 'template.name': { contains: debouncedSearchTerm } },
              { 'template.templateCategory.name': { contains: debouncedSearchTerm } },
              { 'order.orderNumber': { contains: debouncedSearchTerm } },
            ],
          }
        : {}),
    },
  });

  const articles = articlesData?.docs || [];
  const totalPages = articlesData?.totalPages || 0;

  const table = useReactTable({
    data: articles,
    columns,
    pageCount: totalPages,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const deleteArticle = useDeleteArticle();
  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);

  const confirmDeleteArticle = async () => {
    if (articleToDelete) {
      try {
        await deleteArticle.mutateAsync(articleToDelete.id);
        toast.success(t('articles.articleDeletedSuccessfully'));
        setArticleToDelete(null);
      } catch (error) {
        toast.error(t('articles.errorDeletingArticle'));
      }
    }
  };

  return (
    <>
      {!hideFilters && (
        <div className="flex items-center">
          <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
          <div className="ml-auto flex items-center gap-4">
            <Select value={activeTab} onValueChange={(value) => setActiveTab(value as 'all' | 'container' | 'single')}>
              <SelectTrigger className="w-[200px]">
                <Filter className="size-4 mr-2 text-muted-foreground" />
                <SelectValue placeholder={t('articles.allArticles')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">{t('articles.allArticles')}</SelectItem>
                <SelectItem value="container">{t('articles.containerArticles')}</SelectItem>
                <SelectItem value="single">{t('articles.singleArticles')}</SelectItem>
              </SelectContent>
            </Select>
            <SearchCombobox
              value={selectedTemplateId}
              onSelect={(value) => setSelectedTemplateId(value || undefined)}
              collectionKey="templates"
              searchKey="name"
              className="h-9 min-w-[280px] w-auto"
              texts={{ selectItemMsg: `${t('common.filterBy')} ${t('templates.template')}` }}
              icon={ListFilter}
            />
            {customActions || (
              <Button onClick={() => navigate('/juh/article-management/articles/new')}>
                <PlusCircle className="size-4 mr-2" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('articles.addArticle')}</span>
              </Button>
            )}
          </div>
        </div>
      )}

      <div className="space-y-4">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : !isLoading ? (
              <EmptyTableRow colSpan={columns.length} />
            ) : (
              <TableLoadingCell colSpan={columns.length} />
            )}
          </TableBody>
        </Table>

        <DataTablePagination table={table} />
      </div>

      {!hideActions && (
        <AlertDialog open={!!articleToDelete} onOpenChange={(open) => !open && setArticleToDelete(null)}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t('articles.deleteArticle')}</AlertDialogTitle>
              <AlertDialogDescription>{t('articles.deleteArticleConfirmation')}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
              <AlertDialogAction onClick={confirmDeleteArticle}>{t('common.delete')}</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}
