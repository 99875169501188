import { SearchCombobox } from '@/shared/components/inputs';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

import type { AssignmentFormValues } from '../types';

interface ArticleAssignmentCardProps {
  control: Control<AssignmentFormValues>;
  disabled?: boolean;
}

export function ArticleAssignmentCard({ control, disabled = false }: ArticleAssignmentCardProps) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.assignment.articleAssignment')}</CardTitle>
        <CardDescription>{t('articles.assignment.articleAssignmentDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="order"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('orders.order')}</FormLabel>
                <FormControl>
                  <SearchCombobox
                    value={field.value || undefined}
                    onSelect={(value) => {
                      field.onChange(value);
                    }}
                    collectionKey="orders"
                    searchKey="orderNumber"
                    texts={{
                      selectItemMsg: t('articles.assignment.selectOrder'),
                      searchPlaceholder: t('articles.assignment.searchOrder'),
                    }}
                    disabled={disabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
