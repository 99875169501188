import { PayloadApiError } from '@/core/api';
import { useAuth } from '@/core/auth';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/shared/components/ui';
import z from '@/shared/utils/zod-translations';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

export function Login() {
  const { t } = useIntl();
  const { login, loading: isLoading } = useAuth();
  const location = useLocation();

  const formSchema = z.object({
    email: z.string().min(1).email(),
    password: z.string().min(1),
  });

  const defaultEmail = import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_EMAIL || '' : '';
  const defaultPassword = import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_PASSWORD || '' : '';

  // Get email from URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get('email') || '';

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: emailFromQuery || defaultEmail,
      password: defaultPassword,
    },
  });

  useEffect(() => {
    const subscription = form.watch(() => {
      form.clearErrors('root');
    });
    return () => subscription.unsubscribe();
  }, [form]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await login({
        email: values.email,
        password: values.password,
      });
    } catch (error) {
      if (error instanceof PayloadApiError) {
        if (error.statusCode === 401) {
          form.setError('root', { message: t('auth.invalidEmailOrPassword') });
        }
      } else {
        form.setError('root', { message: t('errors.generalError') });
      }
    }
  }

  return (
    <div>
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('auth.login')}</h1>
        <p className="text-muted-foreground text-balance">{t('auth.loginDescription')}</p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('auth.email')}</FormLabel>
                <FormControl>
                  <Input placeholder="name@beispiel.de" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('auth.password')}</FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex items-center justify-between">
            <Link to="/auth/forgot-password" className="text-sm underline">
              {t('auth.forgotPassword')}
            </Link>
          </div>

          <FormMessage>{form.formState.errors.root?.message}</FormMessage>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? t('auth.loggingIn') : t('auth.login')}
          </Button>
        </form>
      </Form>
    </div>
  );
}
