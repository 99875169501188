import { SearchCombobox } from '@/shared/components/inputs';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import { ArrowDown, ArrowUp, ExternalLink, Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import type { Control } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';

import type { ContainerAndChildTemplatesFormValues } from '../types';

interface TemplateChildCardProps {
  control: Control<ContainerAndChildTemplatesFormValues>;
  currentTemplateId?: string;
  disabled?: boolean;
}

export function TemplateChildCard({ control, currentTemplateId, disabled = false }: TemplateChildCardProps) {
  const { t } = useIntl();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'childTemplates',
    keyName: 'fieldId',
  });

  const [lastAddedIndex, setLastAddedIndex] = useState<number | null>(null);

  const childTemplates = useWatch({
    control,
    name: 'childTemplates',
  });

  const childTemplateValues = useWatch({
    control,
    name: 'childTemplates',
  });

  const getExcludeIds = (currentIndex: number) => {
    const excludeIds = childTemplates
      ?.filter((_, index) => index !== currentIndex)
      .map((template) => template.template)
      .filter((id): id is string => id !== '');

    if (currentTemplateId) {
      excludeIds?.push(currentTemplateId);
    }

    return excludeIds;
  };

  return (
    <Card className="mt-2">
      <CardHeader>
        <CardTitle>{t('contents.childTemplates')}</CardTitle>
        <CardDescription>{t('contents.childTemplatesDescription')}</CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Table className="border-b">
          <TableHeader>
            <TableRow>
              <TableHead className="w-[120px] pl-5">{t('common.move')}</TableHead>
              <TableHead>{t('templates.template')}</TableHead>
              <TableHead className="w-[50px]">{t('common.quantity')}</TableHead>
              <TableHead className="w-[50px] pr-5">{t('common.actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.fieldId}>
                <TableCell className="pl-5">
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => move(index, Math.max(0, index - 1))}
                    disabled={index === 0}
                    className="size-8"
                    type="button"
                    aria-label={t('common.moveUp')}
                  >
                    <ArrowUp className="size-4" />
                  </Button>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => move(index, Math.min(fields.length - 1, index + 1))}
                    disabled={index === fields.length - 1}
                    className="size-8"
                    type="button"
                    aria-label={t('common.moveDown')}
                  >
                    <ArrowDown className="size-4" />
                  </Button>
                </TableCell>
                <TableCell>
                  <FormField
                    control={control}
                    name={`childTemplates.${index}.template`}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormControl>
                          <SearchCombobox
                            className="w-full h-8"
                            value={field.value || ''}
                            onSelect={(selectedValue) => {
                              field.onChange(selectedValue as string);
                            }}
                            collectionKey="templates"
                            searchKey="name"
                            excludeIds={getExcludeIds(index)}
                            error={fieldState.error?.message}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={index === lastAddedIndex}
                            disabled={disabled}
                          />
                        </FormControl>
                        {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <FormField
                    control={control}
                    name={`childTemplates.${index}.quantity`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(parseInt(e.target.value, 10) || undefined)}
                            className="w-20 h-8"
                            disabled={disabled}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </TableCell>
                <TableCell className="pr-5">
                  <div className="flex">
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="size-8"
                      onClick={() => remove(index)}
                      disabled={disabled}
                    >
                      <Trash className="size-4" />
                    </Button>

                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="size-8"
                      disabled={!childTemplateValues?.[index]?.template}
                      onClick={() =>
                        window.open(
                          `/juh/article-management/templates/${childTemplateValues?.[index]?.template}`,
                          '_blank',
                        )
                      }
                    >
                      <ExternalLink className="size-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              append({
                template: '',
                quantity: 1,
                order: fields.length,
              });
              setLastAddedIndex(fields.length);
            }}
            type="button"
            className={cn('w-full rounded-none rounded-b text-muted-foreground hover:text-foreground')}
            aria-label={t('contents.addChildTemplate')}
            disabled={disabled}
          >
            <Plus className="size-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
