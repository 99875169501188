import { cn } from '@/shared/utils';
import type { ReactNode } from 'react';

interface PageHeadingProps {
  children: ReactNode;
  className?: string;
}

export function PageHeading({ children, className }: PageHeadingProps) {
  return (
    <div className="flex items-center h-9">
      <h1 className={cn('text-2xl font-bold tracking-tight', className)}>{children}</h1>
    </div>
  );
}
