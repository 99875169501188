import { PageHeading } from '@/shared/components/layout';
import { ArticleTable } from '@/shared/components/tables';
import { useIntl } from '@tiny-intl/react';

export function ArticleOverview() {
  const { t } = useIntl();

  return (
    <>
      <div>
        <PageHeading>{t('stock.stock')}</PageHeading>
      </div>
      <ArticleTable />
    </>
  );
}
