import { FormControl, FormItem, FormMessage } from '../../form';
import { Switch } from '../../switch';
import AutoFormLabel from '../common/label';
import AutoFormTooltip from '../common/tooltip';
import type { AutoFormInputComponentProps } from '../types';

export default function AutoFormCheckbox({
  label,
  isRequired,
  field,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  const { showLabel: _showLabel, ...fieldPropsWithoutShowLabel } = fieldProps;
  const showLabel = _showLabel === undefined ? true : _showLabel;

  return (
    <div className="flex flex-row items-center space-x-2">
      <FormItem className="flex w-full flex-col justify-start ">
        {showLabel && <AutoFormLabel label={fieldConfigItem?.label || label} isRequired={isRequired} />}
        <FormControl>
          <div className="pt-2">
            <Switch checked={field.value} onCheckedChange={field.onChange} {...fieldPropsWithoutShowLabel} />
          </div>
        </FormControl>
        <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
        <FormMessage />
      </FormItem>
    </div>
  );
}
