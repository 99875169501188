import { useDeleteOrder, useOrders, useOrganization } from '@/core/api';
import { useAuth } from '@/core/auth';
import { SearchField } from '@/shared/components/inputs';
import { SearchCombobox } from '@/shared/components/inputs';
import { PageHeading } from '@/shared/components/layout';
import { DataTablePagination, TableLoadingCell } from '@/shared/components/tables';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  EmptyTableRow,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  ToggleGroup,
  ToggleGroupItem,
} from '@/shared/components/ui';
import type { Order } from '@johanniter-offshore/backend';
import type { ColumnDef, ColumnFiltersState, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { ArrowRight, CalendarRange, ListFilter, MoreHorizontal, PlusCircle, Table as TableIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { OrdersTimeline } from '../components/order-timeline/orders-timeline';

const OrganizationCell = ({ organizationId }: { organizationId: string }) => {
  const { data: organization, isLoading } = useOrganization(organizationId);
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{organization?.name || '-'}</div>;
};

const ActionCell = ({ order, onDelete }: { order: Order; onDelete: (order: Order) => void }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';

  if (!isJuhUser) {
    return (
      <Button
        variant="ghost"
        size="icon"
        onClick={() => navigate(`${basePath}/${order.id}`)}
        aria-label={t('common.view')}
      >
        <ArrowRight className="size-4" />
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`${basePath}/${order.id}`)}>{t('common.edit')}</DropdownMenuItem>
        {isJuhAdmin && <DropdownMenuItem onClick={() => onDelete(order)}>{t('common.delete')}</DropdownMenuItem>}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export function OrdersOverview() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(
    user?.organization ? String(user.organization) : undefined,
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [view, setView] = useState<'table' | 'timeline'>('table');

  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/orders';

  const { data: ordersData, isLoading } = useOrders({
    page: view === 'table' ? pagination.pageIndex + 1 : 1,
    limit: view === 'table' ? pagination.pageSize : 0,
    where: {
      and: [
        ...(view === 'table' ? [{ parent: { equals: null } }] : []),
        ...(searchTerm
          ? [
              {
                or: [
                  {
                    orderNumber: {
                      contains: searchTerm,
                    },
                  },
                  {
                    title: {
                      contains: searchTerm,
                    },
                  },
                ],
              },
            ]
          : []),
        ...(selectedOrganizationId
          ? [
              {
                organization: {
                  equals: selectedOrganizationId,
                },
              },
            ]
          : []),
      ],
    },
  });

  const deleteOrder = useDeleteOrder();
  const [orderToDelete, setOrderToDelete] = useState<Order | null>(null);

  const confirmDeleteOrder = async () => {
    if (orderToDelete) {
      try {
        await deleteOrder.mutateAsync(orderToDelete.id);
        toast.success(t('orders.orderDeletedSuccessfully'));
        setOrderToDelete(null);
      } catch (error) {
        toast.error(t('orders.errorDeletingOrder'));
      }
    }
  };

  const columns: ColumnDef<Order>[] = [
    {
      accessorKey: 'orderNumber',
      header: t('orders.orderNumber'),
      cell: ({ row }) => <div>{row.getValue('orderNumber')}</div>,
    },
    {
      accessorKey: 'title',
      header: t('orders.title'),
      cell: ({ row }) => <div>{row.getValue('title')}</div>,
    },
    {
      accessorKey: 'organization',
      header: t('organization.organization'),
      cell: ({ row }) => <OrganizationCell organizationId={row.getValue('organization') as string} />,
    },
    {
      accessorKey: 'startDate',
      header: t('orders.startDate'),
      cell: ({ row }) => {
        const date = row.getValue('startDate') as string;
        return <div>{date ? DateTime.fromISO(date).toFormat('dd.MM.yyyy') : '-'}</div>;
      },
    },
    {
      accessorKey: 'endDate',
      header: t('orders.endDate'),
      cell: ({ row }) => {
        const date = row.getValue('endDate') as string;
        return <div>{date ? DateTime.fromISO(date).toFormat('dd.MM.yyyy') : '-'}</div>;
      },
    },
    {
      accessorKey: 'createdAt',
      header: t('common.createdAt'),
      cell: ({ row }) => {
        const date = row.getValue('createdAt') as string;
        return <div>{DateTime.fromISO(date).toFormat('dd.MM.yyyy')}</div>;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell order={row.original} onDelete={setOrderToDelete} />,
    },
  ];

  const table = useReactTable({
    data: ordersData?.docs ?? [],
    columns,
    pageCount: ordersData?.totalPages ?? 0,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  return (
    <>
      <PageHeading>{isJuhUser ? t('orders.orders') : t('orders.myOrders')}</PageHeading>

      <div className="flex items-center">
        <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
        <div className="ml-auto flex items-center gap-4">
          <ToggleGroup
            type="single"
            value={view}
            onValueChange={(value) => value && setView(value as 'table' | 'timeline')}
          >
            <ToggleGroupItem value="table" aria-label={t('common.tableView')}>
              <TableIcon className="size-4" />
            </ToggleGroupItem>
            <ToggleGroupItem value="timeline" aria-label={t('common.timelineView')}>
              <CalendarRange className="size-4" />
            </ToggleGroupItem>
          </ToggleGroup>
          {isJuhUser && (
            <SearchCombobox
              value={selectedOrganizationId}
              onSelect={(value) => setSelectedOrganizationId(value || undefined)}
              collectionKey="organizations"
              searchKey="name"
              className="h-9 min-w-[280px] w-auto"
              texts={{ selectItemMsg: `${t('common.filterBy')} ${t('organization.organization')}` }}
              icon={ListFilter}
            />
          )}
          {isJuhUser && (
            <Button onClick={() => navigate(`${basePath}/new`)}>
              <PlusCircle className="size-4 mr-2" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('orders.addOrder')}</span>
            </Button>
          )}
        </div>
      </div>

      <div className="space-y-4">
        {view === 'table' ? (
          <>
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <TableLoadingCell colSpan={columns.length} />
                ) : table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <EmptyTableRow colSpan={columns.length} />
                )}
              </TableBody>
            </Table>

            <DataTablePagination table={table} />
          </>
        ) : ordersData?.docs && ordersData?.docs.length > 0 ? (
          <OrdersTimeline orders={ordersData?.docs || []} groupByOrganization />
        ) : (
          <div className="text-center">{t('common.noResults')}</div>
        )}
      </div>

      <AlertDialog open={!!orderToDelete} onOpenChange={(open) => !open && setOrderToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('orders.deleteOrder')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('orders.deleteOrderConfirmation', { name: orderToDelete?.orderNumber || '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteOrder}>{t('common.delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
