import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/shared/components/ui';
import type { BreadcrumbData, CustomRouteObjectUnion } from '@/shared/types/router';
import { useIntl } from '@tiny-intl/react';
import { Link, useMatches } from 'react-router-dom';

interface BreadcrumbItemProps {
  data: BreadcrumbData;
  pathname: string;
  showSeparator: boolean;
}

const DynamicBreadcrumbItem = ({ data, pathname, showSeparator }: BreadcrumbItemProps) => {
  const { t } = useIntl();
  const { label, query } = data;

  const { data: queryData } = query?.queryFn(query.id) ?? {};
  const title = queryData?.[query?.dataKey ?? 'title'] ?? (label ? t(label) : '');

  return (
    <BreadcrumbItem>
      <BreadcrumbLink asChild>
        <Link to={pathname}>{title}</Link>
      </BreadcrumbLink>
      {showSeparator && <BreadcrumbSeparator />}
    </BreadcrumbItem>
  );
};

export const BreadcrumbNav = () => {
  const matches = useMatches() as (ReturnType<typeof useMatches>[number] & {
    handle?: CustomRouteObjectUnion['handle'];
  })[];

  const breadcrumbItems: Array<{ data: BreadcrumbData; pathname: string }> = [];

  matches
    .filter((match) => Boolean(match.handle?.breadcrumb))
    .forEach((match) => {
      const breadcrumbResult =
        typeof match.handle?.breadcrumb === 'function' ? match.handle.breadcrumb(match) : match.handle?.breadcrumb;

      if (Array.isArray(breadcrumbResult)) {
        breadcrumbResult.forEach((item, index) => {
          const pathParts = match.pathname.split('/');
          const pathLength = index === 0 ? pathParts.length - 3 : pathParts.length;
          const currentPath = `/${pathParts.slice(1, pathLength).join('/')}`;
          breadcrumbItems.push({ data: item, pathname: currentPath });
        });
      } else if (breadcrumbResult) {
        breadcrumbItems.push({
          data: typeof breadcrumbResult === 'string' ? { label: breadcrumbResult } : breadcrumbResult,
          pathname: match.pathname,
        });
      }
    });

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        {breadcrumbItems.map((item, index) => {
          const showSeparator = index < breadcrumbItems.length - 1;
          return (
            <DynamicBreadcrumbItem
              key={`${item.pathname}-${index}`}
              data={item.data}
              pathname={item.pathname}
              showSeparator={showSeparator}
            />
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
