import { useCategories, useDeleteCategory } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout';
import type { TreeDataItem } from '@/shared/components/tree';
import { Tree } from '@/shared/components/tree';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from '@/shared/components/ui';
import { Placeholder } from '@/shared/components/ui/placeholder';
import type { Category } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle, TagsIcon } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const transformCategoriesToTreeData = (categories: Category[]): TreeDataItem[] => {
  const categoryMap = new Map<string, TreeDataItem & { children: TreeDataItem[] }>(
    categories.map((category) => [category.id, { ...category, children: [] }]),
  );

  const rootCategories: TreeDataItem[] = [];

  categoryMap.forEach((category) => {
    if ('parent' in category && category.parent) {
      const parentCategory = categoryMap.get(category.parent as string);
      if (parentCategory) {
        parentCategory.children.push(category as TreeDataItem);
      }
    } else {
      rootCategories.push(category as TreeDataItem);
    }
  });

  return rootCategories;
};

export function CategoriesOverview() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const isJuhAdmin = currentUser?.role === 'juh-admin';
  const navigate = useNavigate();
  const { data: categoriesData, isLoading } = useCategories({
    limit: 0,
  });
  const deleteCategory = useDeleteCategory();
  const [categoryToDelete, setCategoryToDelete] = useState<TreeDataItem | null>(null);

  const treeData = categoriesData ? transformCategoriesToTreeData(categoriesData.docs) : [];

  const handleEditCategory = (category: TreeDataItem) => {
    navigate(`/juh/article-management/categories/${category.id}`);
  };
  const handleCreateCategory = () => {
    navigate('/juh/article-management/categories/new');
  };
  const handleDeleteCategory = (category: TreeDataItem) => {
    setCategoryToDelete(category);
  };

  const confirmDeleteCategory = async () => {
    if (categoryToDelete) {
      try {
        await deleteCategory.mutateAsync(categoryToDelete.id);
        toast.success(t('categories.categoryDeletedSuccessfully'));
        setCategoryToDelete(null);
      } catch (error) {
        toast.error(t('categories.errorDeletingCategory'));
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <PageHeading>{t('categories.categories')}</PageHeading>
        {!isLoading && treeData.length > 0 && (
          <Button disabled={!isJuhAdmin} onClick={handleCreateCategory}>
            <PlusCircle className="size-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('categories.addCategory')}</span>
          </Button>
        )}
      </div>

      {isLoading ? (
        <div className="text-muted-foreground flex flex-col items-center justify-center p-6 text-center text-sm">
          {t('common.loading')}
        </div>
      ) : treeData.length > 0 ? (
        <Tree
          data={treeData}
          className="w-full border rounded-lg"
          onEditTreeItem={handleEditCategory}
          onDeleteTreeItem={handleDeleteCategory}
          disableActions={!isJuhAdmin}
        />
      ) : (
        <Placeholder
          className="min-h-[400px]"
          icon={TagsIcon}
          title={t('categories.noCategories')}
          action={
            <Button onClick={handleCreateCategory} disabled={!isJuhAdmin}>
              <PlusCircle className="size-3.5 mr-2" />
              <span>{t('categories.addFirstCategory')}</span>
            </Button>
          }
        />
      )}

      <AlertDialog open={!!categoryToDelete} onOpenChange={(open) => !open && setCategoryToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('categories.deleteCategory')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('categories.deleteCategoryConfirmation', { name: categoryToDelete?.name || '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteCategory}>{t('common.delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
