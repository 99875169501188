import { useGlobalMetadata, useUpdateGlobalMetadata } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout';
import type { FormSchema } from '@/shared/components/metadata';
import {
  MetadataBuilder,
  formFieldsToJsonSchema,
  formSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
} from '@/shared/components/metadata';
import { Button, Card, CardContent, CardDescription, CardHeader, CardTitle, Form } from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

export function ManageArticleMetadata() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const { data: globalMetadata, isLoading } = useGlobalMetadata();
  const updateGlobalMetadata = useUpdateGlobalMetadata();
  const [isUpdating, setIsUpdating] = useState(false);

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      metadata: globalMetadata ? jsonSchemaToFormFields(globalMetadata.articleMetadata as JsonSchemaType) : [],
    },
  });

  useEffect(() => {
    if (globalMetadata && !isLoading) {
      form.reset({
        metadata: jsonSchemaToFormFields(globalMetadata.articleMetadata as JsonSchemaType),
      });
    }
  }, [globalMetadata, isLoading, form]);

  const handleSubmit = async (data: FormSchema) => {
    if (!isJuhAdmin) return;
    const jsonSchema = formFieldsToJsonSchema(data.metadata);
    try {
      setIsUpdating(true);
      await updateGlobalMetadata.mutateAsync({ articleMetadata: jsonSchema });
      toast.success(t('metadata.articleMetadataUpdatedSuccessfully'));
    } catch (error) {
      handleMetadataValidationErrors(error, data.metadata, jsonSchema, form, t);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <div className="flex items-center justify-between">
          <PageHeading>{t('metadata.articleMetadata')}</PageHeading>
          <Button type="submit" disabled={!isJuhAdmin || isUpdating}>
            {isUpdating ? t('common.saving') : t('common.save')}
          </Button>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>{t('metadata.articleMetadata')}</CardTitle>
            <CardDescription>{t('metadata.articleMetadataDescription')}</CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <MetadataBuilder disabled={!isJuhAdmin} loading={isLoading} />
          </CardContent>
        </Card>
      </form>
    </Form>
  );
}
