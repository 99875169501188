import { PayloadApiError, payloadClient } from '@/core/api';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
} from '@/shared/components/ui';
import z from '@/shared/utils/zod-translations';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export function ResetPassword() {
  const { t } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  if (!token) navigate('/auth/forgot-password');

  const formSchema = z
    .object({
      password: z.string().min(8).max(256),
      passwordRepeat: z.string().min(8).max(256),
    })
    .refine((data) => data.password === data.passwordRepeat, {
      message: t('auth.invalidPasswordRepeat'),
      path: ['passwordRepeat'],
    });

  type PasswordResetForm = z.infer<typeof formSchema>;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newPasswordSet, setNewPasswordSet] = useState<boolean>(false);
  const [tokenInvalidOrExpired, setTokenInvalidOrExpired] = useState<boolean>(false);

  const form = useForm<PasswordResetForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  });

  async function onSubmit(values: PasswordResetForm) {
    try {
      setIsLoading(true);
      await payloadClient.resetPassword({
        token: token || '',
        password: values.password,
      });
      setNewPasswordSet(true);
    } catch (error) {
      if (error instanceof PayloadApiError && error.statusCode === 403) {
        setTokenInvalidOrExpired(true);
      } else {
        form.setError('root', { message: t('errors.generalError') });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const loginUrl = email ? `/auth/login?email=${encodeURIComponent(email)}` : '/auth/login';

  return (
    <div>
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('auth.resetPassword')}</h1>
        {!newPasswordSet && !tokenInvalidOrExpired && (
          <p className="text-muted-foreground text-balance">{t('auth.resetPasswordDescription')}</p>
        )}
      </div>
      <div className="mt-6 space-y-4">
        {!newPasswordSet && !tokenInvalidOrExpired && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.newPassword')}</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="passwordRepeat"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.passwordRepeat')}</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormMessage>{form.formState.errors.root?.message}</FormMessage>
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? t('auth.resettingPassword') : t('auth.resetPassword')}
              </Button>
            </form>
          </Form>
        )}

        {newPasswordSet && (
          <div className="space-y-4 text-center">
            <div className="text-sm">{t('auth.passwordWasUpdated')}</div>

            <Link className="block text-center text-sm underline" to={loginUrl}>
              {t('auth.goToLogin')}
            </Link>
          </div>
        )}

        {tokenInvalidOrExpired && (
          <div className="space-y-2 text-center">
            <div className="text-destructive text-sm">{t('auth.passwordResetTokenExpired')}</div>
            <Link className="block text-sm underline" to="/auth/forgot-password">
              {t('auth.requestNewPasswordReset')}
            </Link>
          </div>
        )}

        <Separator className="my-4" />

        <Link className="block text-center text-sm underline" to={loginUrl}>
          {t('auth.rememberedPassword')}
        </Link>
      </div>
    </div>
  );
}
