import { payloadClient } from '@/core/api';
import type { Where } from '@/core/api';
import type { JsonSchemaType, ResolvedChildrenMap, Template } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Query keys for better type safety and reusability
export const templateKeys = {
  all: ['templates'] as const,
  lists: () => [...templateKeys.all, 'list'] as const,
  list: (params: TemplateQueryParams) => [...templateKeys.lists(), params] as const,
  details: () => [...templateKeys.all, 'detail'] as const,
  detail: (id: string) => [...templateKeys.details(), id] as const,
  metadata: () => [...templateKeys.all, 'metadata'] as const,
  resolvedMetadata: (id?: string) => [...templateKeys.metadata(), id] as const,
  children: () => [...templateKeys.all, 'children'] as const,
  resolvedChildren: (id?: string) => [...templateKeys.children(), id] as const,
} as const;

// Types for better type safety
export interface TemplateQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Template | `-${keyof Template}`;
  disabled?: boolean;
}

export interface TemplateUpdateParams {
  id: string;
  data: Partial<Template>;
}

// Hooks with improved TypeScript types
export const useTemplates = (params?: TemplateQueryParams) => {
  return useQuery({
    queryKey: templateKeys.list(params ?? {}),
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'templates',
        ...params,
      });
      return response;
    },
  });
};

export const useTemplate = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: templateKeys.detail(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'templates',
        id,
      });
      return response;
    },
  });
};

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Template>) => {
      const response = await payloadClient.create({
        collection: 'templates',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.all });
    },
  });
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: TemplateUpdateParams) => {
      const response = await payloadClient.updateById({
        collection: 'templates',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.all });
    },
  });
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'templates',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.all });
    },
  });
};

export const useResolvedTemplateMetadata = (templateId?: string) => {
  return useQuery({
    queryKey: templateKeys.resolvedMetadata(templateId),
    queryFn: async () => {
      const response = await payloadClient.customRequest<{ metadata: JsonSchemaType }>({
        subpath: `/templates/${templateId}/resolved-metadata`,
        method: 'GET',
      });
      return response.metadata;
    },
    enabled: !!templateId,
  });
};

export const useResolvedTemplateChildren = (templateId?: string) => {
  return useQuery({
    queryKey: templateKeys.resolvedChildren(templateId),
    queryFn: async () => {
      const response = await payloadClient.customRequest<{
        template: string;
        children: ResolvedChildrenMap;
      }>({
        subpath: `/templates/${templateId}/resolved-children`,
        method: 'GET',
      });
      return response;
    },
    enabled: !!templateId,
  });
};
