import { z } from 'zod';

export const detailsSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
});

export const categorySchema = z.object({
  category: z.string().nullable(),
});

export const inspectionsSchema = z.object({
  name: z.string().min(1),
  interval: z.string().min(1),
  checklist: z
    .array(
      z.object({
        item: z.string().min(1),
        order: z.number().int().min(0),
        id: z.string().nullable().optional(),
      }),
    )
    .nullable()
    .optional(),
  id: z.string().nullable().optional(),
});

export const inspectionsFormSchema = z.object({
  inspections: z.array(inspectionsSchema),
});

export const metadataSchema = z.object({
  metadata: z.array(
    z.object({
      key: z.string().min(1),
      type: z.string().min(1),
      required: z.boolean(),
      id: z.string().nullable().optional(),
    }),
  ),
});

export const containerAndChildTemplatesSchema = z.object({
  isContainer: z.boolean().default(false),
  childTemplates: z.array(
    z.object({
      template: z.string(),
      quantity: z.number().min(1),
      order: z.number().optional(),
    }),
  ),
});

export const templateFilesSchema = z.object({
  files: z.array(
    z.object({
      name: z.string().min(1),
      description: z.string().optional(),
      file: z.union([z.instanceof(File), z.string()]).optional(),
      id: z.string().optional(),
    }),
  ),
});
