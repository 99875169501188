import { useCreateOrder, useOrder, useOrders, useUpdateOrder } from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import { Button, Separator, ToggleGroup, ToggleGroupItem } from '@/shared/components/ui';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { CalendarRange, PlusCircle, Table as TableIcon } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { OrderForm } from '../components/order-form';
import { OrderSubOrders } from '../components/order-sub-orders';
import { OrdersTimeline } from '../components/order-timeline/orders-timeline';
import type { OrderFormData } from '../schema';

export function OrderDetails() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const isEditMode = Boolean(id);
  const { data: order } = useOrder(id!, { disabled: !isEditMode });

  const [error, setError] = useState<PayloadApiError | undefined>();
  const [view, setView] = useState<'table' | 'timeline'>('table');

  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/orders';

  const { data: subOrdersData, isLoading: isLoadingSubOrders } = useOrders({
    limit: 0,
    where: {
      parent: {
        equals: id,
      },
    },
  });

  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();

  const handleSubmit = async (data: OrderFormData) => {
    if (!isJuhUser) return;

    try {
      setError(undefined);
      if (isEditMode) {
        await updateOrder.mutateAsync({ id: order!.id, data });
      } else {
        const response = await createOrder.mutateAsync({
          ...data,
          type: 'root-order',
        });
        navigate(`${basePath}/${response.doc.id}`);
      }
      toast.success(t('common.savedSuccessfully'));
    } catch (err) {
      setError(err as PayloadApiError);
      toast.error(t('common.errorSaving'));
    }
  };

  if (isEditMode && !order) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={isJuhUser ? t(isEditMode ? 'orders.editOrder' : 'orders.newOrder') : t('orders.orderDetails')}
        backHref={basePath}
        primaryAction={
          isJuhUser
            ? {
                label: isEditMode ? t('common.update') : t('common.create'),
                loadingLabel: isEditMode ? t('common.updating') : t('common.creating'),
                form: 'order-form',
                type: 'submit',
                disabled: isEditMode ? updateOrder.isPending : createOrder.isPending,
                isLoading: isEditMode ? updateOrder.isPending : createOrder.isPending,
              }
            : undefined
        }
        secondaryAction={
          isJuhUser
            ? {
                label: t('common.discard'),
                onClick: () => navigate(basePath),
                variant: 'outline',
              }
            : undefined
        }
      />

      <div className="grid gap-6">
        <OrderForm id="order-form" defaultValues={order} onSubmit={handleSubmit} error={error} readOnly={!isJuhUser} />

        {isEditMode && (
          <>
            <Separator />
            <div className="space-y-4 overflow-hidden">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-lg font-semibold">{t('orders.subOrders')}</h2>
                  <p className="text-sm text-muted-foreground">{t('orders.subOrdersDescription')}</p>
                </div>
                <div className="flex items-center gap-4">
                  <ToggleGroup
                    type="single"
                    value={view}
                    onValueChange={(value) => value && setView(value as 'table' | 'timeline')}
                  >
                    <ToggleGroupItem value="table" aria-label={t('common.tableView')}>
                      <TableIcon className="size-4" />
                    </ToggleGroupItem>
                    <ToggleGroupItem value="timeline" aria-label={t('common.timelineView')}>
                      <CalendarRange className="size-4" />
                    </ToggleGroupItem>
                  </ToggleGroup>
                  {isJuhUser && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button>
                          <PlusCircle className="size-4 mr-2" />
                          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('orders.addOrder')}</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('orders.chooseOrderType')}</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => navigate(`${basePath}/${id}/sub-orders/new/article-rental`)}>
                          {t('orders.article-rental')}
                        </DropdownMenuItem>
                        <DropdownMenuItem disabled>{t('orders.missionPlanning')}</DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </div>

              {view === 'table' ? (
                <OrderSubOrders
                  orders={subOrdersData?.docs ?? []}
                  isLoading={isLoadingSubOrders}
                  readOnly={!isJuhUser}
                />
              ) : isLoadingSubOrders ? (
                <div className="text-center">{t('common.loading')}</div>
              ) : subOrdersData?.docs && subOrdersData.docs.length > 0 ? (
                <OrdersTimeline orders={subOrdersData.docs} />
              ) : (
                <div className="text-center">{t('common.noResults')}</div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
