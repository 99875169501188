import { z } from 'zod';

export const createOrderSchema = (
  t: (key: string) => string,
  metadataSchema: z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny> = z.object({}),
  dynamicMetadataSchema: z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny> = z.object({}),
) =>
  z.object({
    orderNumber: z.string().min(1),
    title: z.string().min(1),
    organization: z.string().min(1),
    object: z.string().optional().nullable(),
    startDate: z.string().min(1),
    endDate: z.string().min(1),
    description: z.string().optional(),
    metadata: metadataSchema,
    dynamicMetadataSchema: z
      .object({
        type: z.literal('object'),
        properties: z.record(
          z.object({
            type: z.union([z.literal('string'), z.literal('number'), z.literal('boolean'), z.literal('date')]),
            format: z.literal('date-time').optional(),
            description: z.string(),
          }),
        ),
        required: z.array(z.string()),
        propertyOrder: z.array(z.string()),
      })
      .optional(),
    dynamicMetadataValues: dynamicMetadataSchema,
  });

export type OrderFormData = z.infer<ReturnType<typeof createOrderSchema>>;
