import { useAuth } from '@/core/auth';
import { match } from '@formatjs/intl-localematcher';
import { createTinyIntl } from '@tiny-intl/core';
import { TinyIntlContext, useIntl } from '@tiny-intl/react';
import i18next from 'i18next';
import { type ReactNode, useEffect, useState } from 'react';
import { useMount } from 'react-use';

import type { I18nSupportedLocales } from '../locales';
import { load, supportedLocales } from '../locales';

export const intl = createTinyIntl<string>({
  fallbackLocale: 'de-DE',
  supportedLocales,
  loadDict: async (nextLoc) => {
    const dict = await load(nextLoc as I18nSupportedLocales);
    return dict.messages;
  },
  detectLocale: (detection) => {
    const storedLocale = localStorage.getItem('selectedLocale');
    if (storedLocale && detection.supportedLocales.includes(storedLocale)) {
      return storedLocale as I18nSupportedLocales;
    }
    return match(
      [navigator.language, ...navigator.languages],
      [...detection.supportedLocales],
      detection.fallbackLocale,
    ) as I18nSupportedLocales;
  },
});

intl.subscribe(() => {
  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    htmlEl.lang = intl.locale;
  }
  i18next.changeLanguage(intl.locale);
});

function I18nLoader() {
  const { change, locale } = useIntl();
  const { user } = useAuth();

  const [i18nMounted, setI18nMounted] = useState(false);

  useMount(() => {
    setI18nMounted(false);
    intl
      .mount()
      .then(() => {
        setI18nMounted(true);
      })
      .catch(() => {});
  });

  useEffect(() => {
    if (i18nMounted && user) {
      const newLocale = user?.language || localStorage.getItem('selectedLocale') || 'de-DE';
      if (locale !== newLocale) {
        change(newLocale);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nMounted, user]);

  return <div className={!i18nMounted ? 'fixed inset-0 z-[49]' : ''} />;
}

interface I18nProviderProps {
  children: ReactNode;
}

export function I18nProvider({ children }: I18nProviderProps) {
  return (
    <TinyIntlContext.Provider value={intl}>
      {children}
      <I18nLoader />
    </TinyIntlContext.Provider>
  );
}
