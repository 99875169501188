import { Toggle, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/components/ui';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

interface TabItem {
  value: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
}

interface TabNavProps {
  tabs: TabItem[];
  basePath: string;
  rightContent?: ReactNode;
}

export const TabNavPortal = ({ children }: { children: ReactNode }) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.getElementById('tab-nav-portal-container');
    if (container) {
      setPortalContainer(container);
    }
  }, []);

  if (!portalContainer) return null;

  return createPortal(children, portalContainer);
};

export function TabNav({ tabs, basePath, rightContent }: TabNavProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState<string | undefined>(undefined);

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    setCurrentPath(path);
  }, [location]);

  const isActive = (value: string) => currentPath === value;

  const handleNavigate = (value: string) => {
    navigate(`${basePath}/${value}`);
  };

  return (
    <div className="flex items-center justify-between mb-6">
      <div className="flex flex-wrap gap-2">
        <TooltipProvider>
          {tabs.map((tab) => {
            const toggle = (
              <Toggle
                key={tab.value}
                pressed={isActive(tab.value)}
                onPressedChange={() => !tab.disabled && handleNavigate(tab.value)}
                className={tab.disabled ? 'opacity-50 cursor-not-allowed' : ''}
              >
                {tab.label}
              </Toggle>
            );

            if (tab.tooltip && tab.disabled) {
              return (
                <Tooltip key={tab.value}>
                  <TooltipTrigger asChild>{toggle}</TooltipTrigger>
                  <TooltipContent>
                    <p>{tab.tooltip}</p>
                  </TooltipContent>
                </Tooltip>
              );
            }

            return toggle;
          })}
        </TooltipProvider>
      </div>
      <div id="tab-nav-portal-container" className="ml-auto flex items-center">
        <TabNavPortal>{rightContent}</TabNavPortal>
      </div>
    </div>
  );
}
