import { PayloadApiError, payloadClient } from '@/core/api';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
} from '@/shared/components/ui';
import z from '@/shared/utils/zod-translations';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

const formSchema = z.object({
  email: z.string().min(1).email(),
});

type RequestPasswordResetForm = z.infer<typeof formSchema>;

export function RequestPasswordReset() {
  const { t } = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetRequested, setResetRequested] = useState<boolean>(false);

  const defaultEmail = import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_EMAIL || '' : '';

  const form = useForm<RequestPasswordResetForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: defaultEmail,
    },
  });

  async function onSubmit(values: RequestPasswordResetForm) {
    try {
      setIsLoading(true);
      await payloadClient.forgotPassword({ email: values.email });
      setResetRequested(true);
    } catch (error) {
      if (error instanceof PayloadApiError) {
        form.setError('root', { message: t('errors.generalError') });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('auth.resetPassword')}</h1>
        {!resetRequested && (
          <p className="text-muted-foreground text-balance">{t('auth.requestPasswordResetDescription')}</p>
        )}
      </div>
      <div className="mt-6 space-y-4">
        {!resetRequested ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.email')}</FormLabel>
                    <FormControl>
                      <Input placeholder="name@beispiel.de" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormMessage>{form.formState.errors.root?.message}</FormMessage>
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? t('common.sending') : t('auth.sendResetLink')}
              </Button>
            </form>
          </Form>
        ) : (
          <div className="text-center">
            <div className="text-sm">{t('auth.resetPasswordLinkSent')}</div>
          </div>
        )}

        <Separator />

        <Link className="block text-center text-sm underline" to="/auth/login">
          {t('auth.rememberedPassword')}
        </Link>
      </div>
    </div>
  );
}
