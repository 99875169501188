import { useCreateOrganization, useOrganization, useUpdateOrganization } from '@/core/api';
import { useAuth } from '@/core/auth';
import { UserManagement } from '@/features/shared/users';
import { PageHeader } from '@/shared/components/layout/page-header';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
} from '@/shared/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { organizationFormSchema } from '../schema';
import type { OrganizationFormValues } from '../types';

export default function ManageOrganization() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const createOrganization = useCreateOrganization();
  const updateOrganization = useUpdateOrganization();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isEditMode = !!id;

  const { data: organization } = useOrganization(id || '');
  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (isEditMode && organization) {
      form.reset({
        name: organization.name,
      });
    }
  }, [isEditMode, organization, form]);

  const handleSubmit = async (data: OrganizationFormValues) => {
    if (!isJuhAdmin) return;

    try {
      if (isEditMode) {
        await updateOrganization.mutateAsync({ id, data });
        toast.success(t('organizations.organizationUpdatedSuccessfully'));
      } else {
        const createdOrg = await createOrganization.mutateAsync(data);
        toast.success(t('organizations.organizationCreatedSuccessfully'));

        navigate(`/juh/customer-management/organizations/${createdOrg.doc.id}`);
      }
    } catch (error) {
      toast.error(t('organizations.errorSavingOrganization'));
    }
  };

  return (
    <>
      <PageHeader
        title={isEditMode ? t('organizations.editOrganization') : t('organizations.newOrganization')}
        backHref="/juh/customer-management/organizations"
        actions={
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={() => navigate('/juh/customer-management/organizations')}>
              {t('common.discard')}
            </Button>
            <Button
              onClick={form.handleSubmit(handleSubmit)}
              disabled={(isEditMode ? updateOrganization.isPending : createOrganization.isPending) || !isJuhAdmin}
            >
              {isEditMode
                ? updateOrganization.isPending
                  ? t('common.updating')
                  : t('common.update')
                : createOrganization.isPending
                  ? t('common.creating')
                  : t('common.create')}
            </Button>
          </div>
        }
      />

      <div className="grid gap-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="grid gap-6 md:grid-cols-2">
            <Card className="md:col-span-2">
              <CardHeader>
                <CardTitle>{t('organizations.organizationDetails')}</CardTitle>
                <CardDescription>{t('organizations.organizationDetailsDescription')}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel required>{t('common.name')}</FormLabel>
                        <FormControl>
                          <Input {...field} disabled={!isJuhAdmin} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </form>
        </Form>

        {isEditMode && (
          <>
            <Separator />
            <UserManagement
              allowedRoles={['customer-admin', 'customer-employee']}
              organizationId={id}
              hideInviteButton={!isJuhAdmin}
              title={t('users.users')}
            />
          </>
        )}
      </div>
    </>
  );
}
