import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { ChevronDown, PlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ArticleActionsProps {
  orderId?: string;
  onAssignArticles?: () => void;
}

export function ArticleActions({ orderId, onAssignArticles }: ArticleActionsProps) {
  const { t } = useIntl();
  const navigate = useNavigate();

  const handleCreateArticle = () => {
    if (orderId) {
      navigate(`/juh/article-management/articles/new?orderId=${orderId}`);
    } else {
      navigate('/juh/article-management/articles/new');
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button>
          <PlusCircle className="size-4 mr-2" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('articles.addArticle')}</span>
          <ChevronDown className="size-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.chooseAction')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleCreateArticle}>{t('orders.articleRental.createNewArticle')}</DropdownMenuItem>
        {orderId && (
          <DropdownMenuItem onClick={onAssignArticles}>
            {t('orders.articleRental.assignExistingArticles')}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
