import { useOrders, useOrganization } from '@/core/api';
import type { Order } from '@johanniter-offshore/backend';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import Timeline, { TimelineMarkers, TodayMarker } from 'react-calendar-timeline';
import { useNavigate } from 'react-router-dom';

import './order-timeline-styles.scss';

interface OrdersTimelineProps {
  orders: Order[];
  groupByOrganization?: boolean;
}

interface GroupWithOrg {
  id: string;
  title: string | null;
  orders: Order[];
}

interface TimelineGroup {
  id: string;
  title: ReactElement;
  root?: boolean;
  height?: number;
}

function OrganizationGroup({ id, isExpanded, onToggle }: { id: string; isExpanded?: boolean; onToggle?: () => void }) {
  const { data: organization } = useOrganization(id);
  const { data: rootOrders } = useOrders({
    where: {
      organization: {
        equals: id,
      },
      parent: {
        exists: false,
      },
    },
  });

  const hasRootOrders = (rootOrders?.docs.length ?? 0) > 0;

  return (
    <button
      onClick={hasRootOrders && onToggle ? onToggle : undefined}
      className="flex items-center gap-1 w-full text-left text-sm hover:bg-accent/50 rounded px-1"
      disabled={!hasRootOrders}
    >
      {hasRootOrders ? (
        <span className="text-muted-foreground">
          {isExpanded ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
        </span>
      ) : (
        <span className="w-5" /> // Spacer for alignment
      )}
      <span>{organization?.name || '-'}</span>
    </button>
  );
}

function RootOrderGroup({
  order,
  isExpanded,
  onToggle,
}: {
  order: Order;
  isExpanded?: boolean;
  onToggle?: () => void;
}) {
  const { data: subOrders } = useOrders({
    where: {
      parent: {
        equals: order.id,
      },
    },
  });

  const hasSubOrders = (subOrders?.docs.length ?? 0) > 0;

  return (
    <button
      onClick={hasSubOrders && onToggle ? onToggle : undefined}
      className="flex items-center gap-1 pl-6 w-full text-left text-sm hover:bg-accent/50 rounded px-1"
      disabled={!hasSubOrders}
    >
      {hasSubOrders ? (
        <span className="text-muted-foreground">
          {isExpanded ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
        </span>
      ) : (
        <span className="w-5" /> // Spacer for alignment
      )}
      <span>{order.orderNumber}</span>
    </button>
  );
}

export function OrdersTimeline({ orders, groupByOrganization = false }: OrdersTimelineProps) {
  const navigate = useNavigate();

  // Initialize expandedOrgs with all organizations expanded
  const initialExpandedOrgs = useMemo(() => {
    if (!groupByOrganization) return {};
    return orders.reduce(
      (acc, order) => {
        const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
        acc[orgId] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  }, [orders, groupByOrganization]);

  const [expandedOrgs, setExpandedOrgs] = useState<Record<string, boolean>>(initialExpandedOrgs);
  const [expandedOrders, setExpandedOrders] = useState<Record<string, boolean>>({});

  const toggleOrg = (id: string) => {
    setExpandedOrgs((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleOrder = (id: string) => {
    setExpandedOrders((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const { timelineGroups, timelineItems } = groupByOrganization
    ? // Group by organization
      {
        timelineGroups: Object.values(
          orders.reduce(
            (acc, order) => {
              const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;

              if (!acc[orgId]) {
                acc[orgId] = {
                  id: orgId,
                  title: null,
                  orders: [],
                };
              }
              acc[orgId].orders.push(order);
              return acc;
            },
            {} as Record<string, GroupWithOrg>,
          ),
        )
          .map((org) => {
            const rootOrders = org.orders.filter((order) => !order.parent);
            return [
              // Organization group
              {
                id: `org-${org.id}`,
                title: (
                  <OrganizationGroup id={org.id} isExpanded={expandedOrgs[org.id]} onToggle={() => toggleOrg(org.id)} />
                ),
                root: true,
                height: 40,
              },
              // Root orders if org is expanded
              ...(expandedOrgs[org.id]
                ? rootOrders.map((rootOrder) => ({
                    id: rootOrder.id,
                    title: (
                      <RootOrderGroup
                        order={rootOrder}
                        isExpanded={expandedOrders[rootOrder.id]}
                        onToggle={() => toggleOrder(rootOrder.id)}
                      />
                    ),
                    root: false,
                  }))
                : []),
              // Sub-orders if root order is expanded
              ...(expandedOrgs[org.id]
                ? rootOrders.flatMap((rootOrder) =>
                    expandedOrders[rootOrder.id]
                      ? org.orders
                          .filter((order) => order.parent === rootOrder.id)
                          .map((subOrder) => ({
                            id: subOrder.id,
                            title: <div className="pl-12 text-sm">{subOrder.orderNumber}</div>,
                            root: false,
                          }))
                      : [],
                  )
                : []),
            ];
          })
          .flat() as TimelineGroup[],
        timelineItems: orders
          .filter((order) => {
            if (!order.parent) {
              // Show root orders when org is expanded
              const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
              return expandedOrgs[orgId];
            } else {
              // Show sub-orders when both org and parent order are expanded
              const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
              const parentId = typeof order.parent === 'string' ? order.parent : order.parent.id;
              return expandedOrgs[orgId] && expandedOrders[parentId];
            }
          })
          .map((order) => ({
            id: order.id,
            group: order.id,
            title: order.title,
            start_time: DateTime.fromISO(order.startDate).toMillis(),
            end_time: order.endDate
              ? DateTime.fromISO(order.endDate).toMillis()
              : DateTime.fromISO(order.startDate).plus({ days: 1 }).toMillis(),
          })),
      }
    : // Show each order as its own group
      {
        timelineGroups: orders.map((order) => ({
          id: order.id,
          title: <div className="text-sm">{order.orderNumber}</div>,
        })) as TimelineGroup[],
        timelineItems: orders.map((order) => ({
          id: order.id,
          group: order.id,
          title: order.title,
          start_time: DateTime.fromISO(order.startDate).toMillis(),
          end_time: order.endDate
            ? DateTime.fromISO(order.endDate).toMillis()
            : DateTime.fromISO(order.startDate).plus({ days: 1 }).toMillis(),
        })),
      };

  return (
    <div className="rounded-lg border border-border overflow-hidden relative z-0">
      <Timeline
        groups={timelineGroups}
        items={timelineItems}
        defaultTimeStart={DateTime.now().minus({ months: 1 }).toMillis()}
        defaultTimeEnd={DateTime.now().plus({ months: 12 }).toMillis()}
        canMove={false}
        canResize={false}
        minZoom={30 * 24 * 60 * 60 * 1000}
        maxZoom={365 * 24 * 60 * 60 * 1000}
        lineHeight={40}
        sidebarWidth={250}
        stackItems
        horizontalLineClassNamesForGroup={(group) => (group.root ? ['row-root'] : [])}
        onItemClick={(itemId) => {
          const order = orders.find((o) => o.id === itemId);
          if (order) {
            if (order.parent) {
              const parentId = typeof order.parent === 'string' ? order.parent : order.parent.id;
              navigate(`/juh/order-management/orders/${parentId}/sub-orders/${order.id}/${order.type}`);
            } else {
              navigate(`/juh/order-management/orders/${order.id}`);
            }
          }
        }}
      >
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) => {
              const customStyles = {
                ...styles,
                width: '1px',
                backgroundColor: 'rgba(var(--primary))',
              };
              return <div style={customStyles} />;
            }}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    </div>
  );
}
