import { MetadataAutoForm } from '@/shared/components/metadata';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shared/components/ui';
import { Placeholder } from '@/shared/components/ui/placeholder';
import { useIntl } from '@tiny-intl/react';
import { Package } from 'lucide-react';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';

type ArticleMetadataCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: z.ZodObject<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  templateSelected?: boolean;
};

export const ArticleMetadataCard = ({ schema, form, templateSelected }: ArticleMetadataCardProps) => {
  const { t } = useIntl();
  const hasMetadataFields = Object.keys(schema.shape).length > 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('articles.articleMetadata')}</CardTitle>
        <CardDescription>{t('articles.articleMetadataDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {!templateSelected ? (
          <Placeholder
            icon={Package}
            title={t('articles.assignment.selectTemplate')}
            description={t('articles.selectTemplateForMetadata')}
          />
        ) : hasMetadataFields ? (
          <MetadataAutoForm schema={schema} form={form} />
        ) : (
          <div className="text-sm text-muted-foreground">({t('metadata.noTemplateMetadata')})</div>
        )}
      </CardContent>
    </Card>
  );
};
