import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shared/components/ui';
import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import type React from 'react';
import { useEffect, useState } from 'react';

export const FormatedInterval = ({
  prefix,
  interval,
  prefixFormat = 'in',
}: {
  prefix?: string;
  interval: string;
  prefixFormat?: 'in' | 'every';
}) => {
  const { tc, t } = useIntl();

  const [number, unit] = interval.split(':');
  const numberValue = parseInt(number, 10);
  const unitMap: Record<string, string> = {
    d: tc('time.day', numberValue),
    w: tc('time.week', numberValue),
    m: tc('time.month', numberValue),
    y: tc('time.year', numberValue),
  };

  const formattedPrefix = prefixFormat === 'in' ? t('common.in') : t('time.every');
  const formattedInterval =
    prefixFormat === 'every' && numberValue === 1 ? unitMap[unit] : `${numberValue} ${unitMap[unit]}`;

  return <div>{`${prefix || formattedPrefix} ${formattedInterval}`}</div>;
};

interface IntervalInputProps {
  value?: string | null;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  className?: string;
}

export const IntervalInput: React.FC<IntervalInputProps> = ({ value, onChange, disabled, className }) => {
  const { t } = useIntl();
  const [count, setCount] = useState(value?.split(':')[0] || '');
  const [unit, setUnit] = useState(value?.split(':')[1] || 'm');

  useEffect(() => {
    if (value) {
      const [newCount, newUnit] = value.split(':');
      setCount(newCount || '');
      setUnit(newUnit || 'm');
    } else {
      setCount('');
    }
  }, [value]);

  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCount = e.target.value;
    setCount(newCount);
    if (newCount === '') {
      onChange(null);
    } else {
      onChange(`${newCount}:${unit}`);
    }
  };

  const handleUnitChange = (newUnit: string) => {
    setUnit(newUnit);
    if (count === '') {
      onChange(null);
    } else {
      onChange(`${count}:${newUnit}`);
    }
  };

  return (
    <div className={cn('flex w-full', className)}>
      <Input
        type="number"
        value={count}
        onChange={handleCountChange}
        min="1"
        className="w-12 rounded-r-none text-center focus:z-10"
        disabled={disabled}
      />
      <Select value={unit} onValueChange={handleUnitChange} disabled={disabled}>
        <SelectTrigger className="rounded-l-none border-l-0 focus:z-10">
          <SelectValue placeholder={t('time.selectUnit')} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="d">{t('time.days')}</SelectItem>
          <SelectItem value="w">{t('time.weeks')}</SelectItem>
          <SelectItem value="m">{t('time.months')}</SelectItem>
          <SelectItem value="y">{t('time.years')}</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
