import { useCategories, useTemplate, useUpdateTemplate, useUploadMedia } from '@/core/api';
import { PageHeader } from '@/shared/components/layout/page-header';
import {
  formFieldsToJsonSchema,
  handleMetadataValidationErrors,
  jsonSchemaToFormFields,
} from '@/shared/components/metadata';
import type { JsonSchemaType, Media, Template } from '@johanniter-offshore/backend';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { TemplateForm } from '../components/template-form';
import { useTemplateForms } from '../hooks/use-template-forms';

interface TemplateFile {
  file: string | Media;
  name: string;
  description?: string | null;
  id?: string | null;
}

export function EditTemplate() {
  const { t } = useIntl();
  const updateTemplate = useUpdateTemplate();
  const { data: categoriesData } = useCategories({ limit: 0 });
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadMedia = useUploadMedia();
  const navigate = useNavigate();

  const {
    isJuhAdmin,
    detailsForm,
    categoryForm,
    inspectionsForm,
    metadataForm,
    containerAndChildTemplatesForm,
    templateFilesForm,
    validateForms,
    resetForms,
  } = useTemplateForms();

  const { data: templateData } = useTemplate(id!, { disabled: !id });

  useEffect(() => {
    if (templateData) {
      resetForms({
        details: {
          name: templateData.name,
          description: templateData.description || '',
        },
        category: {
          category: (templateData.category as string) || null,
        },
        inspections: {
          inspections: templateData.inspections || [],
        },
        metadata: {
          metadata: jsonSchemaToFormFields(templateData.metadata as JsonSchemaType) || [],
        },
        containerAndChildTemplates: {
          isContainer: templateData.isContainer || false,
          childTemplates:
            templateData.childTemplates?.map((template) => ({
              template: template.template as string,
              quantity: template.quantity,
              order: template.order || undefined,
            })) || [],
        },
        files: {
          files:
            templateData.files?.map((file: TemplateFile) => ({
              name: file.name,
              description: file.description || '',
              id: file.id || '',
              file: typeof file.file === 'string' ? file.file : file.file.id,
            })) || [],
        },
      });
    }
  }, [templateData, resetForms]);

  const onSubmit = async () => {
    if (!(await validateForms())) {
      return;
    }

    setIsSubmitting(true);

    const detailsData = detailsForm.getValues();
    const categoryData = categoryForm.getValues();
    const inspectionsData = inspectionsForm.getValues();
    const metadataData = metadataForm.getValues();
    const containerAndChildTemplatesData = containerAndChildTemplatesForm.getValues();
    const templateFilesData = templateFilesForm.getValues();

    const jsonSchema = formFieldsToJsonSchema(metadataData.metadata);

    const payload: Partial<Template> = {
      ...detailsData,
      ...categoryData,
      ...inspectionsData,
      metadata: jsonSchema,
      isContainer: containerAndChildTemplatesData.isContainer,
      childTemplates: containerAndChildTemplatesData.isContainer
        ? containerAndChildTemplatesData.childTemplates
            ?.filter((template) => template.template && template.quantity)
            .map((template, index) => ({ ...template, order: index }))
        : undefined,
    };

    if (templateFilesData.files) {
      const existingFiles = (templateData?.files || []) as TemplateFile[];
      const newFiles = templateFilesData.files.filter(
        (file): file is { name: string; description?: string; file: File } => file.file instanceof File,
      );
      const updatedFiles = templateFilesData.files.filter((file) => typeof file.id === 'string');

      const uploadedFiles = await Promise.all(
        newFiles.map(async (fileData) => {
          if (!fileData.file) {
            throw new Error('File is missing');
          }
          const uploadedFile = await uploadMedia.mutateAsync({
            file: fileData.file,
            data: {},
          });
          return { file: uploadedFile.doc.id, name: fileData.name, description: fileData.description };
        }),
      );

      payload.files = [
        ...existingFiles
          .filter((file) =>
            updatedFiles.some(
              (updatedFile) => updatedFile.id === (typeof file.file === 'string' ? file.file : file.file.id),
            ),
          )
          .map((file) => ({
            ...file,
            file: typeof file.file === 'string' ? file.file : file.file.id,
          })),
        ...updatedFiles.map((file) => ({ ...file, file: file.file as string })),
        ...uploadedFiles,
      ];
    }

    try {
      await updateTemplate.mutateAsync({ id: id!, data: payload });
      toast.success(t('common.savedSuccessfully'));
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    } catch (error) {
      if (error && typeof error === 'object' && 'name' in error && error.name === 'PayloadApiError') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payloadError = error as { response?: { errors?: Array<{ message: string; data?: any }> } };
        const circularReferenceError = payloadError.response?.errors?.find(
          (e) => e.message === 'CircularReferenceError',
        );
        const maxDepthError = payloadError.response?.errors?.find((e) => e.message === 'MaxTemplateDepthExceeded');
        const metadataUpdateValidationFailed = payloadError.response?.errors?.find(
          (e) => e.message === 'MetadataUpdateValidationFailed',
        );
        const categoryChangeRequiredFieldsError = payloadError.response?.errors?.find(
          (e) => e.message === 'CategoryChangeRequiredFieldsError',
        );

        if (circularReferenceError || maxDepthError) {
          const errorType = circularReferenceError ? 'circularReference' : 'maxDepth';
          toast.error(t(`templates.${errorType}ErrorTitle`), {
            description: t(`templates.${errorType}ErrorDescription`),
            duration: 25000,
            closeButton: true,
            dismissible: true,
          });

          const errorData = circularReferenceError?.data || maxDepthError?.data;
          const childTemplateId = errorData?.template;
          const index = containerAndChildTemplatesForm
            .getValues()
            .childTemplates?.findIndex((template) => template.template === childTemplateId);
          if (index !== undefined && index !== -1) {
            containerAndChildTemplatesForm.setError(`childTemplates.${index}.template`, {
              type: 'manual',
              message: t(`templates.${errorType}ErrorDescription`),
            });
          }
          return;
        }

        if (jsonSchema && metadataData.metadata !== undefined && metadataUpdateValidationFailed) {
          handleMetadataValidationErrors(error, metadataData.metadata, jsonSchema, metadataForm, t);
          return;
        }
        if (categoryChangeRequiredFieldsError) {
          categoryForm.setError('category', {
            type: 'manual',
            message: t('categories.categoryChangeRequiredFieldsErrorDescription'),
          });
          return;
        }
      }
      toast.error(t('common.errorSaving'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageHeader
        title={t('templates.editTemplate')}
        backHref="/juh/article-management/templates"
        primaryAction={{
          label: t('common.save'),
          loadingLabel: t('common.saving'),
          onClick: onSubmit,
          disabled: isSubmitting,
          isLoading: isSubmitting,
        }}
        secondaryAction={{
          label: t('common.discard'),
          onClick: () => navigate('/juh/article-management/templates'),
          variant: 'outline',
        }}
      />

      <TemplateForm
        detailsForm={detailsForm}
        categoryForm={categoryForm}
        inspectionsForm={inspectionsForm}
        metadataForm={metadataForm}
        containerAndChildTemplatesForm={containerAndChildTemplatesForm}
        templateFilesForm={templateFilesForm}
        categoriesData={categoriesData}
        isJuhAdmin={isJuhAdmin}
        currentTemplateId={id}
        articleCount={templateData?.articleCount || undefined}
      />
    </>
  );
}
