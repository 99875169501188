import { articleKeys } from '@/core/api';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import { toast } from 'sonner';

interface PayloadApiError {
  name: string;
  response?: {
    errors?: Array<{
      message: string;
      data?: unknown;
    }>;
  };
}

export function useArticleErrorHandling() {
  const { t } = useIntl();
  const queryClient = useQueryClient();

  const handleArticleError = (error: unknown): boolean => {
    if (!error || typeof error !== 'object') return false;

    const payloadError = error as PayloadApiError;
    if (payloadError.name !== 'PayloadApiError') return false;

    const childArticleAlreadyAssignedError = payloadError.response?.errors?.find(
      (e) => e.message === 'ChildArticleAlreadyAssigned',
    );

    if (childArticleAlreadyAssignedError) {
      toast.error(t('contents.childArticleAlreadyAssignedError'), {
        description: t('contents.childArticleAlreadyAssignedErrorDescription'),
        duration: 25000,
        closeButton: true,
        dismissible: true,
      });

      queryClient.invalidateQueries({ queryKey: articleKeys.all });
      return true;
    }

    return false;
  };

  return { handleArticleError };
}
