import { useAuth } from '@/core/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  categorySchema,
  containerAndChildTemplatesSchema,
  detailsSchema,
  inspectionsFormSchema,
  metadataSchema,
  templateFilesSchema,
} from '../schema';
import type {
  CategoryFormValues,
  ContainerAndChildTemplatesFormValues,
  DetailsFormValues,
  InspectionsFormValues,
  MetadataFormValues,
  TemplateFilesFormValues,
} from '../types';

export function useTemplateForms() {
  const { user: currentUser } = useAuth();
  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const detailsForm = useForm<DetailsFormValues>({
    resolver: zodResolver(detailsSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const categoryForm = useForm<CategoryFormValues>({
    resolver: zodResolver(categorySchema),
    defaultValues: {
      category: null,
    },
  });

  const inspectionsForm = useForm<InspectionsFormValues>({
    resolver: zodResolver(inspectionsFormSchema),
    defaultValues: {
      inspections: [],
    },
  });

  const metadataForm = useForm<MetadataFormValues>({
    resolver: zodResolver(metadataSchema),
    defaultValues: {
      metadata: [],
    },
  });

  const containerAndChildTemplatesForm = useForm<ContainerAndChildTemplatesFormValues>({
    resolver: zodResolver(containerAndChildTemplatesSchema),
    defaultValues: {
      isContainer: false,
      childTemplates: [],
    },
  });

  const templateFilesForm = useForm<TemplateFilesFormValues>({
    resolver: zodResolver(templateFilesSchema),
    defaultValues: {
      files: [],
    },
  });

  const validateForms = async () => {
    const isDetailsValid = await detailsForm.trigger();
    const isCategoryValid = await categoryForm.trigger();
    const isInspectionsValid = await inspectionsForm.trigger();
    const isMetadataValid = await metadataForm.trigger();
    const isContainerValid = await containerAndChildTemplatesForm.trigger();
    const isFilesValid = await templateFilesForm.trigger();

    return (
      isDetailsValid && isCategoryValid && isInspectionsValid && isMetadataValid && isContainerValid && isFilesValid
    );
  };

  const resetForms = useCallback(
    (data?: {
      details?: DetailsFormValues;
      category?: CategoryFormValues;
      inspections?: InspectionsFormValues;
      metadata?: MetadataFormValues;
      containerAndChildTemplates?: ContainerAndChildTemplatesFormValues;
      files?: TemplateFilesFormValues;
    }) => {
      if (data) {
        detailsForm.reset(data.details);
        categoryForm.reset(data.category);
        inspectionsForm.reset(data.inspections);
        metadataForm.reset(data.metadata);
        containerAndChildTemplatesForm.reset(data.containerAndChildTemplates);
        templateFilesForm.reset(data.files);
      } else {
        detailsForm.reset();
        categoryForm.reset();
        inspectionsForm.reset();
        metadataForm.reset();
        containerAndChildTemplatesForm.reset();
        templateFilesForm.reset();
      }
    },
    [detailsForm, categoryForm, inspectionsForm, metadataForm, containerAndChildTemplatesForm, templateFilesForm],
  );

  return {
    isJuhAdmin,
    detailsForm,
    categoryForm,
    inspectionsForm,
    metadataForm,
    containerAndChildTemplatesForm,
    templateFilesForm,
    validateForms,
    resetForms,
  };
}
