import { TableLoadingCell } from '@/shared/components/tables';
import { EmptyTableRow, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/components/ui';
import type { Object } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';

import { ObjectTableActions } from './object-table-actions';

interface ObjectTableProps {
  objects: Object[];
  onEdit: (object: Object) => void;
  onDelete: (object: Object) => void;
  isLoading?: boolean;
}

export const ObjectTable = ({ objects, onEdit, onDelete, isLoading }: ObjectTableProps) => {
  const { t } = useIntl();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('common.name')}</TableHead>
          <TableHead>{t('common.description')}</TableHead>
          <TableHead className="text-right">
            <span className="sr-only">{t('common.actions')}</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <TableLoadingCell colSpan={3} />
        ) : objects.length > 0 ? (
          objects.map((object) => (
            <TableRow key={object.id}>
              <TableCell className="truncate">{object.name}</TableCell>
              <TableCell className="max-w-[200px] truncate">{object.description}</TableCell>
              <TableCell className="text-right">
                <ObjectTableActions onEdit={() => onEdit(object)} onDelete={() => onDelete(object)} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <EmptyTableRow />
        )}
      </TableBody>
    </Table>
  );
};
