import {
  Sidebar as ShadcnSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
} from '@/shared/components/ui';
import type { RouteProp } from '@/shared/types/route-prop';
import { useIntl } from '@tiny-intl/react';
import { useLocation } from 'react-router-dom';

import { Logo } from './logo';
import { NavUser } from './nav-user';
import { SidebarLink } from './sidebar-link';

interface SidebarProps {
  routes: RouteProp[];
}

export const Sidebar = ({ routes }: SidebarProps) => {
  const { t } = useIntl();
  const location = useLocation();

  return (
    <ShadcnSidebar variant="floating">
      <SidebarHeader>
        <Logo />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>{t('common.navigation')}</SidebarGroupLabel>
          <SidebarMenu>
            {routes.map((route) => (
              <SidebarLink
                key={route.to}
                route={route}
                currentPath={location.pathname}
                isActive={location.pathname.startsWith(route.to)}
              />
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
    </ShadcnSidebar>
  );
};
