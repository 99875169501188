import { useAuth } from '@/core/auth';
import type { Organization } from '@johanniter-offshore/backend';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { payloadClient } from '.';
import type { Where } from './types';

// Query keys for better type safety and reusability
export const organizationKeys = {
  all: ['organizations'] as const,
  lists: () => [...organizationKeys.all, 'list'] as const,
  list: (params: OrganizationQueryParams) => [...organizationKeys.lists(), params] as const,
  details: () => [...organizationKeys.all, 'detail'] as const,
  detail: (id: string) => [...organizationKeys.details(), id] as const,
} as const;

// Types for better type safety
export interface OrganizationQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Organization | `-${keyof Organization}`;
  disabled?: boolean;
}

export const useOrganizations = (params?: OrganizationQueryParams) => {
  return useQuery({
    queryKey: organizationKeys.list(params ?? {}),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'organizations',
        ...params,
      });
      return response;
    },
  });
};

export const useCurrentUserOrganization = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['currentUserOrganization'],
    queryFn: async () => {
      if (!user?.organization) return null;

      const organizationId = typeof user.organization === 'string' ? user.organization : user.organization.id;

      const response = await payloadClient.findById({
        collection: 'organizations',
        id: organizationId,
      });
      return response;
    },
    enabled: !!user?.organization,
  });
};

export const useOrganization = (id: string) => {
  return useQuery({
    queryKey: organizationKeys.detail(id),
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'organizations',
        id,
      });
      return response;
    },
    enabled: !!id,
  });
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Organization> }) => {
      const response = await payloadClient.updateById({
        collection: 'organizations',
        id,
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.all });
    },
  });
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Organization>) => {
      const response = await payloadClient.create({
        collection: 'organizations',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.all });
    },
  });
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'organizations',
        id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.all });
    },
  });
};
