import { z } from 'zod';

export const articleDetailsSchema = z.object({
  template: z.string().min(1),
  expiryDate: z.date().nullable(),
});

export const assignmentSchema = z.object({
  order: z.string().nullable(),
});

export const articleFilesSchema = z.object({
  articleFiles: z.array(
    z.object({
      name: z.string().min(1),
      description: z.string().optional(),
      file: z.union([z.instanceof(File), z.string()]).optional(),
      id: z.string().optional(),
    }),
  ),
});
