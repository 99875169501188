import { useCreateObject, useDeleteObject, useObjects, useUpdateObject } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui';
import type { Object } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { ObjectForm, type ObjectFormValues, ObjectTable } from '../components';

export function ManageObjects() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const [page] = useState(1);
  const [selectedObject, setSelectedObject] = useState<Object | undefined>(undefined);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const { data: objectsData, isLoading } = useObjects({
    page,
    limit: 0,
    sort: 'name',
  });

  const createObject = useCreateObject();
  const updateObject = useUpdateObject();
  const deleteObject = useDeleteObject();

  const handleEditObject = (object: Object) => {
    setSelectedObject(object);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedObject(undefined);

    setTimeout(() => {
      document.body.style.pointerEvents = '';
    }, 200);
  };

  const handleSubmit = async (data: ObjectFormValues) => {
    if (selectedObject) {
      await updateObject.mutateAsync({
        id: selectedObject.id,
        data,
      });
      toast.success(t('objects.objectUpdatedSuccessfully'));
      handleCloseEditDialog();
    } else {
      await createObject.mutateAsync(data);
      setIsCreateDialogOpen(false);
      toast.success(t('objects.objectCreatedSuccessfully'));
    }
  };

  const handleDelete = async (object: Object) => {
    await deleteObject.mutateAsync(object.id);
    toast.success(t('objects.objectDeletedSuccessfully'));
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <PageHeading>{t('objects.objects')}</PageHeading>
        <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
          <DialogTrigger asChild>
            <Button disabled={!isJuhAdmin}>
              <PlusCircle className="size-4 mr-2" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('objects.createObject')}</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('objects.createObject')}</DialogTitle>
              <DialogDescription>{t('objects.createObjectDescription')}</DialogDescription>
            </DialogHeader>
            <ObjectForm onSubmit={handleSubmit} />
          </DialogContent>
        </Dialog>
      </div>

      <ObjectTable
        objects={objectsData?.docs ?? []}
        onEdit={handleEditObject}
        onDelete={handleDelete}
        isLoading={isLoading}
      />

      <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('objects.editObject')}</DialogTitle>
            <DialogDescription>{t('objects.editObjectDescription')}</DialogDescription>
          </DialogHeader>
          <ObjectForm object={selectedObject} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
