import { cn } from '@/shared/utils';
import type { LucideIcon } from 'lucide-react';
import type { ReactNode } from 'react';

export interface PlaceholderProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: LucideIcon;
  title: string;
  description?: string;
  className?: string;
  action?: ReactNode;
}

export function Placeholder({ icon: Icon, title, description, className, action, ...props }: PlaceholderProps) {
  return (
    <div
      className={cn(
        `
          flex min-h-[200px] flex-col items-center justify-center gap-4 rounded-lg border border-dashed border-muted p-8 text-center
          text-muted-foreground
        `,
        className,
      )}
      {...props}
    >
      {Icon && <Icon className="size-12 opacity-50" />}
      <div>
        <p className="text-sm font-medium">{title}</p>
        {description && <p className="text-xs">{description}</p>}
      </div>
      {action && <div className="mt-2">{action}</div>}
    </div>
  );
}
