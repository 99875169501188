import { Button } from '@/shared/components/ui';
import type { ButtonProps } from '@/shared/components/ui/button';
import { useIntl } from '@tiny-intl/react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

type Action = ButtonProps & {
  label: string;
  loadingLabel?: string;
  isLoading?: boolean;
};

interface PageHeaderProps {
  title: string;
  backHref?: string;
  primaryAction?: Action;
  secondaryAction?: Action;
  actions?: React.ReactNode;
}

export function PageHeader({ title, backHref, primaryAction, secondaryAction, actions }: PageHeaderProps) {
  const { t } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="sticky top-0 z-10 -mx-6 -mt-6 mb-6 backdrop-blur-sm">
      <div className="bg-background/80 px-6 py-4">
        <div className="flex items-center gap-4">
          {backHref && (
            <Button variant="outline" size="icon" className="size-7" onClick={() => navigate(backHref)}>
              <ChevronLeft className="size-4" />
              <span className="sr-only">{t('common.back')}</span>
            </Button>
          )}

          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight">{title}</h1>

          {actions ||
            ((primaryAction || secondaryAction) && (
              <div className="flex items-center gap-2">
                {secondaryAction && (
                  <Button {...secondaryAction} variant={secondaryAction.variant || 'outline'}>
                    {secondaryAction.isLoading ? secondaryAction.loadingLabel : secondaryAction.label}
                  </Button>
                )}
                {primaryAction && (
                  <Button {...primaryAction} variant={primaryAction.variant || 'default'}>
                    {primaryAction.isLoading ? primaryAction.loadingLabel : primaryAction.label}
                  </Button>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
