import { useCreateOrder, useOrder, useUpdateOrder } from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import { Separator } from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ArticleRentalAssignedArticles } from '../components/article-rental-assigned-articles';
import { OrderForm } from '../components/order-form';
import type { OrderFormData } from '../schema';

export function ArticleRentalDetails() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id: parentId, subOrderId } = useParams();
  const isEditMode = subOrderId !== 'new';
  const { data: parentOrder } = useOrder(parentId!);
  const { data: subOrder } = useOrder(subOrderId!, { disabled: !isEditMode });
  const [error, setError] = useState<PayloadApiError | undefined>();

  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';

  const handleSubmit = async (data: OrderFormData) => {
    if (!isJuhUser) return;

    try {
      setError(undefined);
      if (isEditMode && subOrder) {
        await updateOrder.mutateAsync({ id: subOrder.id, data });
      } else {
        const response = await createOrder.mutateAsync({
          ...data,
          type: 'article-rental',
          parent: parentOrder!.id,
          organization: parentOrder!.organization,
          object: parentOrder!.object,
        });
        navigate(`${basePath}/${parentOrder!.id}/sub-orders/${response.doc.id}/article-rental`);
      }
      toast.success(t('common.savedSuccessfully'));
    } catch (err) {
      setError(err as PayloadApiError);
      toast.error(t('common.errorSaving'));
    }
  };

  if (!parentOrder || (isEditMode && !subOrder)) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={isJuhUser ? t(isEditMode ? 'orders.editSubOrder' : 'orders.newSubOrder') : t('orders.subOrderDetails')}
        backHref={`${basePath}/${parentId}`}
        primaryAction={
          isJuhUser
            ? {
                label: isEditMode ? t('common.update') : t('common.create'),
                loadingLabel: isEditMode ? t('common.updating') : t('common.creating'),
                form: 'sub-order-form',
                type: 'submit',
                disabled: isEditMode ? updateOrder.isPending : createOrder.isPending,
                isLoading: isEditMode ? updateOrder.isPending : createOrder.isPending,
              }
            : undefined
        }
        secondaryAction={
          isJuhUser
            ? {
                label: t('common.discard'),
                onClick: () => navigate(`${basePath}/${parentId}`),
                variant: 'outline',
              }
            : undefined
        }
      />

      <div className="grid gap-6">
        <OrderForm
          id="sub-order-form"
          defaultValues={subOrder || { organization: parentOrder.organization }}
          onSubmit={handleSubmit}
          readOnlyAssignments
          readOnly={!isJuhUser}
          error={error}
          orderType="article-rental"
        />
        {isEditMode && subOrder && (
          <>
            <Separator />
            <ArticleRentalAssignedArticles orderId={subOrder.id} readOnly={!isJuhUser} />
          </>
        )}
      </div>
    </>
  );
}
